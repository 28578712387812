import {get, postById, putById} from "@/service/base";

/**
 * 获取日程日期
 * @returns {Promise<unknown>}
 * @constructor
 * @param code
 */
export function getSelectDate(code){
    return get("/conferenceAffairs/schedule/selectDate/"+code)
}

/**
 * 获取日程会场
 * @returns {Promise<unknown>}
 * @constructor
 * @param code
 */
export function getSelectSite(code){
    return get("/conferenceAffairs/schedule/selectSite/"+code)
}
/**
 * 添加日程会场
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function postAddressSchedule(data){
    return postById('/conferenceAffairs/schedule/site/addInfo',data)
}

/**
 * 修改日程会场
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function putAddressSchedule(data){
    return putById('/conferenceAffairs/schedule/site/updInfo',data)
}

/**
 * 日程会场上下移
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function putAddressScheduleMove(type,id){
    return putById('/conferenceAffairs/schedule/site/move/'+type+'/'+id)
}

/**
 * 删除日程会场
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function delAddressSchedule(data){
    return putById('/conferenceAffairs/schedule/site/delInfo',data)
}



/**
 * 获取会议日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param code
 */
export function getSelectSchedule(code,date,data){
    return get("/conferenceAffairs/schedule/selectSchedule/"+code+'/'+date,data)
}


/**
 * 添加一级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function postSelectSchedule(data){
    return postById('/conferenceAffairs/schedule/scheduleIn/addInfo',data)
}

/**
 * 修改一级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function putSelectSchedule(data){
    return putById('/conferenceAffairs/schedule/scheduleIn/updInfo',data)
}

/**
 * 删除一级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function delSelectSchedule(data){
    return putById('/conferenceAffairs/schedule/scheduleIn/delInfo',data)
}




/**
 * 添加二级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function postSelectScheduleAcademic(data){
    return postById('/conferenceAffairs/schedule/academic/addInfo',data)
}

/**
 * 修改二级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function putSelectScheduleAcademic(data){
    return putById('/conferenceAffairs/schedule/academic/updInfo',data)
}

/**
 * 删除二级日程
 * @returns {Promise<unknown>}
 * @constructor
 * @param data
 */
export function delSelectScheduleAcademic(data){
    return putById('/conferenceAffairs/schedule/academic/delInfo',data)
}
