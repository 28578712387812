<template>
<div>
<!--  会议酒店管理  -->
  <a-modal
      :title="title"
      :visible="visible"
      @ok="okBtn"
      @cancel="cancelBtn"
      width="850px"
  >
    <div class="ConferenceHotel">
      <div class="ConferenceHotel-form">
        <div style="margin-bottom: 20px">会议酒店信息：</div>
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
        >
          <a-row>
            <a-col :span="11">
              <a-form-model-item label="酒店名称" prop="region">
                <a-input v-model="form.name" placeholder="请输入" style="width: 230px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item label="酒店地址" prop="region">
                <a-input v-model="form.name" placeholder="请输入" style="width: 230px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item label="酒店联系人" prop="region">
                <a-input v-model="form.name" placeholder="请输入" style="width: 230px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item label="联系人电话" prop="region">
                <a-input v-model="form.name" placeholder="请输入" style="width: 230px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="primary" style="margin-top: 2px">保存</a-button>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div>
        <a-button @click="openModel" type="primary" style="margin:20px 0">+ 新增会议室</a-button>
        <a-table :columns="columns" :data-source="data" :pagination="false">
          <span slot="serialNumber" slot-scope="text, record">
            1
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="editBtn" style="margin-right: 10px">修改</a>
            <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delBtn"
            ><a href="#">删除</a></a-popconfirm>
          </span>
        </a-table>
        <div class="pagination">
          <a-pagination show-quick-jumper :default-current="current" :total="total" @change="onChange" />
        </div>
      </div>
    </div>
  </a-modal>

  <a-modal
      :title="titleModel"
      :visible="visibleModel"
      @ok="okModelBtn"
      @cancel="cancelModelBtn"
      width="400px"
  >
    <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rulesModel"
        :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="会议室地址" prop="roomAddress">
            <a-input v-model="formModel.roomAddress" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="会场名称" prop="name">
            <a-input v-model="formModel.name" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</div>
</template>

<script>
export default {
name: "HotelManagement",
  props:{
    visible:{
      type:Boolean,
    },
    title:{
      type:String
    }
  },
  data(){
  return{
    form:{},
    rules:{
      // region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
    },
    columns:[
      {
        title: '序号',
        scopedSlots: { customRender: 'serialNumber' },
      },
      {
        title: '会议室地址',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: '会场名称',
        key: 'assembly',
        dataIndex: 'assembly',
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    data:[],
    total:0,
    current:1,
    visibleModel:false,
    formModel:{},
    rulesModel:{
      roomAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
      name: [{ required: true, message: '请输入', trigger: 'blur' }],
    },
    titleModel:'',
  }
  },
  methods:{
    cancelBtn(){
      console.log(1111111)
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值(开关)
    },
    onChange(page,pageSize){
      this.current = page;
    },
    delBtn(){

    },
    okBtn(){

    },
    editBtn(){
      this.titleModel = '修改';
      this.visibleModel = true;
    },
    cancelModelBtn(){
      this.visibleModel = false;
    },
    okModelBtn(){

    },
    openModel(){
      this.titleModel = '新增';
      this.visibleModel = true;
    },
  }
}
</script>

<style scoped lang="scss">
 .ConferenceHotel{
   font-weight: bold;
   color: #333333;
 }
 .ConferenceHotel-form{
   margin-bottom: 20px;
   border-bottom: 1px solid #e0e0e0;
 }
 .pagination{
   width: 100%;
   display: flex;
   justify-content: right;
   margin-top: 10px;
 }
</style>
