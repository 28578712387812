<template>
  <div class="indexBox">
    <a-spin :spinning="spinning">
    <div class="MeetingSchedule">
      <div class="date">
        <div class="date-title">日期：</div>
        <div class="date-list">
          <div :class="['listView',item === isSelectDate ? 'isSelect':'NoSelect']"
               v-for="(item,index) in dateList"
               @click="selectDteBtn(item)"
               :key="index">
            <span>{{dateChinese(item)}}</span>
          </div>
        </div>
      </div>
      <div class="meetingPlace">
        <div class="meetingPlace-title">会场：</div>
        <div class="meetingPlace-list">
            <div v-if="addressList.length > 1" :class="['meetingPlaceListView',isSelectPlace === '' ? 'isSelect':'NoSelect']"
                 @click="selectPlaceBtn('')">
              <span>全部会场</span>
            </div>
          <div :class="['meetingPlaceListView',item.id === isSelectPlace ? 'isSelect':'NoSelect']"
                 v-for="(item,index) in addressList"
                 @click="selectPlaceBtn(item.id)"
                 :key="index">
              <span>{{item.address}}</span>
            </div>
          <div class="meetingPlaceListView">
              <span><a @click="openAddressModel">设置</a></span>
            </div>
        </div>

      </div>
    </div>
    <div class="scheduleView">
      <div class="schedule">
        <h3>{{dateChinese(isSelectDate)}}（{{getWeekdayInChinese(isSelectDate)}}）</h3>
        <span>
<!--          <a-button type="primary" style="margin-right: 15px" @click="ExportTemplate">-->
<!--            <a-icon type="vertical-align-top" />-->
<!--            导出模板-->
<!--          </a-button>-->
<!--          <a-button style="border: 1px solid #45A5E6;color: #45A5E6;margin-right: 15px;position: relative">-->
          <a-button style="border: 1px solid #45A5E6;color: #45A5E6;margin-right: 15px;"
                    @click="openExport">
            <a-icon type="vertical-align-bottom"/>
            日程导入
<!--            <input type="file" @change="toLeadBtn" style="opacity: 0;width: 100%;height: 100%;position: absolute;top: 0;left: 0">-->
          </a-button>
          <a-button type="primary" @click="addBtn('添加')">+ 添加日程</a-button>
        </span>
      </div>
      <div class="NoList" v-if="data.length === 0">
        <a-icon type="frown" /> 暂无数据
      </div>

      <div class="scheduleList" v-if="data.length">
        <div class="scheduleListHeader">
          <div style="width: 33.33%;padding: 10px">时间</div>
          <div class="headerMid">专题/讲题</div>
          <div style="width: 33.33%;padding: 10px">讲者主持</div>
        </div>
        <div v-for="(table,index) in tableData" :key="index">
          <div v-if="table.siteName" class="siteName">
            <span>{{table.siteName}}</span>
          </div>
<!--          :showHeader="index === 0 ? true:false"       style="background-color: #5DC8FD;cursor: pointer;" -->
          <a-table :columns="columns"
                   :data-source="[table]"
                   :pagination="false"
                   :showHeader="false"
                   :rowClassName="setRowClassName"
                   :customRow='rowClick'
                   bordered>
            <span slot="customTime" slot-scope="text, record">
              {{record.beginTime.slice(11)}} - {{record.endTime.slice(11)}}
            </span>

            <span slot="directs" slot-scope="text, record">
              <div v-for="tag in record.expertList">
                {{tag.name}} - {{tag.hospital}}
              </div>
            </span>
          </a-table>

          <a-table v-if="table.academicList.length"
                   :columns="columns"
                   :row-key="record => record.id"
                   :data-source="table.academicList"
                   :rowClassName="setRowClassNameTwo"
                   :showHeader="false"
                   :customRow='rowClickChild'
                   :pagination="false" bordered>
            <span slot="customTime" slot-scope="text, record">
              {{record.beginTime.slice(11)}} - {{record.endTime.slice(11)}}
            </span>
            <span slot="directs" slot-scope="text, record">
              <div v-for="tag in record.expertList">
                {{tag.name}} - {{tag.hospital}}
              </div>
            </span>
          </a-table>
          <div v-if="table.title !== '茶歇'" class="addBtn" @click="addBtnTask(table)">
            <span><a>+ 添加</a></span>
          </div>
        </div>
      </div>
    </div>
    </a-spin>
<!--    添加/修改日程-->
    <a-modal
        width="600px"
        :title="title+'日程'"
        :visible="visible"
        @cancel="addClose"
        :footer="null"
    >
      <a-form-model
          style="margin-bottom: 100px"
          :model="form"
          :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }"
          :rules="addRules"
          ref="ruleForm_add"
      >
        <a-form-model-item label="日期">
          {{isSelectDate}}
        </a-form-model-item>
<!--        <a-form-model-item label="会场" prop="meeting">-->
        <a-form-model-item label="会场">
          <a-select v-model="form.meeting"
                    placeholder="请输入"
                    style="width: 300px" @change="handleChange">
            <a-select-option v-for="meet in addressList" :value="meet.id">
              {{meet.address}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="时间" prop="startTime">
          <a-row>
            <a-col :span="13">
              <a-time-picker
                  style="width: 140px"
                  placeholder="请选择开始时间"
                  v-model="startTime"
                  :disabledHours="disabledStartHours"
                  :disabledMinutes="disabledStartMinutes"
                  @change="selectStartTime"
                  @openChange="blurStartTime"
                  hideDisabledOptions
                  format="HH:mm" />
            </a-col>
            <a-col :span="1">-</a-col>

            <a-col :span="6">
              <a-time-picker
                  style="width: 140px"
                  placeholder="请选择结束时间"
                  v-model="endTime"
                  :disabledHours="disabledEndHours"
                  :disabledMinutes="disabledEndMinutes"
                  @change="selectEndTime"
                  hideDisabledOptions
                  format="HH:mm" />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input
              placeholder="请输入" v-model="form.title"
              style="width: 300px"
          />
        </a-form-model-item>
        <a-form-model-item label="主持">
<!--          <a-select-->
<!--              mode="multiple"-->
<!--              v-model="form.direct"-->
<!--              style="width: 300px"-->
<!--              placeholder="请选择（支持多个）"-->
<!--              @change="changeDirect"-->
<!--          >-->
<!--            <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">-->
<!--              {{ (i + 9).toString(36) + i }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
          <a-select
              mode="multiple"
              show-search
              option-filter-prop="children"
              @popupScroll="popupScroll_expert"
              @search="select_expert"
              v-model="form.direct"
              style="width: 300px"
              placeholder="请选择（支持多个）"
          >
            <a-select-option
                v-for="item in expertList"
                :value="item.expertId"
            >
              {{ item.expertName }} - {{item.expertHospital}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>

      <div v-if="title === '添加'"
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
          <a-button @click="addClose"> 取消 </a-button>
<!--          <a-button style="margin-left: 15px" type="primary" @click="addSubmitNoClose">-->
          <a-button style="margin-left: 15px" type="primary" @click="addSubmit('nuClose')">
            提交并继续
          </a-button>
          <a-button style="margin-left: 15px;border: 1px solid #45A5E6;color: #45A5E6" @click="addSubmit">
            提交
          </a-button>
      </div>
      <div v-else
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            display:'flex',
            justifyContent:'space-between',
            zIndex: 1,
          }"
      >
          <div>
            <a-popconfirm placement="topLeft" ok-text="是" cancel-text="否" @confirm="confirmSchedule">
              <template slot="title">是否确认删除，删除后子级数据同样被删除？</template>
              <a-button style="color: red;border: 1px solid red">删除</a-button>
            </a-popconfirm>
          </div>
          <div>
            <a-button @click="addClose"> 取消 </a-button>
            <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
              提交
            </a-button>
          </div>
      </div>
    </a-modal>
<!--    添加/修改任务-->
    <a-modal
        width="600px"
        :title="titleTask+'任务'"
        :visible="visibleTask"
        @cancel="addCloseTask"
        :footer="null"
    >
      <a-form-model
          style="margin-bottom: 100px"
          :model="formTask"
          :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }"
          :rules="addRulesTask"
          ref="ruleForm_Task"
      >
        <a-form-model-item label="日期">
          {{isSelectDate}}
        </a-form-model-item>
        <a-form-model-item label="专题">
          {{scheduleList.title}}
        </a-form-model-item>
        <a-form-model-item label="时间" prop="startTime">
          <a-row>
            <a-col :span="13">
              <a-time-picker
                  style="width: 140px"
                  placeholder="请选择开始时间"
                  v-model="startTime"
                  :disabledHours="disabledStartHoursSecond"
                  :disabledMinutes="disabledStartMinutesSecond"
                  @change="selectStartTimeTask"
                  @openChange="blurStartTimeTask"
                  hideDisabledOptions
                  format="HH:mm" />
            </a-col>
            <a-col :span="1">-</a-col>

            <a-col :span="6">
              <a-time-picker
                  style="width: 140px"
                  placeholder="请选择结束时间"
                  v-model="endTime"
                  :disabledHours="disabledEndHoursSecond"
                  :disabledMinutes="disabledEndMinutesSecond"
                  @change="selectEndTimeTask"
                  hideDisabledOptions
                  format="HH:mm" />
            </a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="讲者">
<!--          <a-select-->
<!--              v-model="formTask.expertId"-->
<!--              style="width: 300px"-->
<!--              placeholder="请选择"-->
<!--              @change="changeDirect"-->
<!--          >-->
<!--            <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">-->
<!--              {{ (i + 9).toString(36) + i }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->

          <a-select
              mode="multiple"
              show-search
              option-filter-prop="children"
              @popupScroll="popupScroll_expert"
              @search="select_expert"
              v-model="formTask.direct"
              style="width: 300px"
              placeholder="请选择（支持多个）"
          >
            <a-select-option
                v-for="item in expertList"
                :value="item.expertId"
            >
              {{ item.expertName }} - {{item.expertHospital}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="标题">
          <a-input
              placeholder="请输入" v-model="formTask.title"
              style="width: 300px"
          />
        </a-form-model-item>
      </a-form-model>

      <div v-if="title === '添加'"
           :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
      >
        <a-button @click="addCloseTask"> 取消 </a-button>
<!--        <a-button style="margin-left: 15px" type="primary" @click="addSubmitTaskNoClose">-->
        <a-button style="margin-left: 15px" type="primary" @click="addSubmitTask('nuClose')">
          提交并继续
        </a-button>
        <a-button style="margin-left: 15px;border: 1px solid #45A5E6;color: #45A5E6" @click="addSubmitTask">
          提交
        </a-button>
      </div>
      <div v-else
           :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            display:'flex',
            justifyContent:'space-between',
            zIndex: 1,
          }"
      >
        <div>
          <a-popconfirm placement="topLeft" ok-text="是" cancel-text="否" @confirm="confirmTask">
            <template slot="title">是否确认删除？</template>
            <a-button style="color: red;border: 1px solid red">删除</a-button>
          </a-popconfirm>
        </div>
        <div>
          <a-button @click="addCloseTask"> 取消 </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="addSubmitTask">
            提交
          </a-button>
        </div>
      </div>
    </a-modal>
<!--    会场   -->
    <a-drawer
        width="680px"
        title="会场"
        placement="right"
        :visible="visibleAddress"
        @close="onClose"
    >
      <div style="display: flex;justify-content: space-between;align-items: center">
        <span style="width: 60px">会场：</span>
        <a-input placeholder="请输入" v-model="addressVal"/>
        <a-button type="primary"
                  style="margin-left: 10px"
                  @click="addAddress">{{addressAction}}</a-button>
      </div>
      <div style="margin-top: 20px">
        <a-table :columns="columnsAddress" :data-source="addressList" :pagination="false">
           <span slot="sort" slot-scope="text, record">
             <a @click="putAddressListMove('up',record.id)">上移</a>
             <a style="margin-left: 10px;" @click="putAddressListMove('down',record.id)">下移</a>
           </span>
           <span slot="action" slot-scope="text, record">
             <a @click="editAddress(record)" style="margin-right: 10px;">修改</a>
             <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delBtn(record)"
             >
               <a>删除</a>
             </a-popconfirm>
           </span>
        </a-table>
      </div>
    </a-drawer>
<!-- 导入框 -->
    <a-modal
        width="600px"
        title="从Excel导入"
        :visible="importModal"
        :footer="null"
        @cancel="importClose"
    >
      请选择要导入的数据文件，<a @click="downloadTemplate">点击下载模板</a>
      <!-- <div style="margin:10px 0 15px 0;position: relative;"  class="uploadImg">
        <input style="width:100%;height: 100%;position: absolute;right:-20px" id="uploadFile" type="file" @change="changeFileElsx" ref="uploadFile_inputFile"

        accept=".xlsx,.xls" />
      </div> -->
      <div style="display: flex;justify-content: space-between;align-items: flex-end;">
        <div>
          <div class="upload_2File">
            <input :disabled="loadImg" style=" cursor:pointer;width:100%;height: 100%;opacity: 0;" id="uploadFile"
                   type="file"
                   @change="changeFileElsx"
                   ref="uploadFile_inputFile"   />
          </div>
          <div style="margin-top:10px;" v-if="changeFile!==''">
            文件： {{ changeFile }}
          </div>
          <!-- <span style="font-size: 10px">支持扩展名：.psd</span> -->
          <div style="color: red" v-if="loadImg">
            上传中...
          </div>
          <!-- <div style="font-size: 8px;width: 600px">{{addForm.psdFile}}</div> -->
        </div>
        <div>
          <a-button v-if="changeFile" @click="cancelImport" style="margin-right:20px">取消</a-button>
          <a-button type="primary" @click="importOk">开始导入</a-button>
        </div>
      </div>
      <a-collapse v-model="activeKey" style="margin-top:20px;" class="collapse">
        <a-collapse-panel key="1" header="查看文件上传要求">
          <p>1.支持Excel 2007及以上版本文件</p>
          <p>2.为保证数据顺利导入，请下载导入模板</p>
          <p>3.一次导入数据不能超过500行</p>
          <div class="blueTable">
            <a-table :columns="VIPcolumns"
                     :data-source="VIPdata"
                     size="small"
                     :pagination="false"
                     :scroll="{ x: 'calc(800px + 50%)'}">
              <span slot="nameVal" slot-scope="text, record">
                <span style="color: red">{{record.name}}</span>
              </span>
              <span slot="sex" slot-scope="text, record">
              <span style="color: red">{{record.sex}}</span>
            </span>
              <span slot="sfzh" slot-scope="text, record">
              <span style="color: red">{{record.sfzh}}</span>
            </span>
              <span slot="birthday" slot-scope="text, record">
              <span style="color: red">{{record.birthday}}</span>
            </span>
              <span slot="province" slot-scope="text, record">
              <span style="color: red">{{record.province}}</span>
            </span>
            </a-table>

          </div>
        </a-collapse-panel>
      </a-collapse>



      <!-- <a-menu
        style="width: 100%"
        :default-selected-keys="['1']"
        :open-keys.sync="openKeys"
        mode="inline"
        class="menuList"
      >
        <a-sub-menu key="sub1" >
          <span slot="title"><span>查看文件上传要求</span></span>
          <a-menu-item-group key="g1">
            <template slot="title">
              <p>1.支持Excel 2007及以上版本文件</p>
            <p>2.为保证数据顺利导入，请下载导入模板</p>
            <p>3.一次导入数据不能超过500行</p>
            </template>
          </a-menu-item-group>
        </a-sub-menu>
      </a-menu> -->


    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  delAddressSchedule, delSelectSchedule, delSelectScheduleAcademic,
  getSelectDate, getSelectSchedule,
  getSelectSite,
  postAddressSchedule, postSelectSchedule, postSelectScheduleAcademic,
  putAddressSchedule, putAddressScheduleMove, putSelectSchedule, putSelectScheduleAcademic
} from "@/service/meetingSchedule";
import {
  confereeSelectAll, exportMb,
  exportTemplate,
  getAddInfo,
  postImportMember,
  scheduleImportRcExcel
} from "@/service/participants";

const columns = [
  {
    title: '时间',
    key: 'time',
    width:'33.33%',
    scopedSlots: { customRender: 'customTime' },
  },
  {
    title: '专题/讲题',
    dataIndex: 'title',
    key: 'title',
    width:'33.33%',
  },
  {
    title: '讲者主持',
    key: 'directs',
    width:'33.33%',
    scopedSlots: { customRender: 'directs' },
  }
];
import headerConfig from "@/service/api_header_config";
export default {
  props:{
    code:{
      type:String,
    }
  },
  async created() {
    await this.getDateList();
    await this.getAddressList();
    await this.getScheduleList();
  },
  data(){
    return{
      VIPcolumns:[
        {
          title: '会议日期',
          dataIndex: 'name',
          key:'name',
          scopedSlots: { customRender: 'nameVal' },
        },
        {
          title: '会场',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
        },
        {
          title: '一级日程名称',
          dataIndex: 'sfzh',
          scopedSlots: { customRender: 'sfzh' },
        },
        {
          title: '一级日程时间段(开始)',
          dataIndex: 'birthday',
          scopedSlots: { customRender: 'birthday' },
        },
        {
          title: '一级日程时间段(结束)',
          dataIndex: 'province',
          scopedSlots: { customRender: 'province' },
        },
        {
          title: '二级日程名称',
          dataIndex: 'city',
        },
        {
          title: '二级日程时间段(开始)',
          dataIndex: 'unit',
        },
        {
          title: '二级日程时间段(结束)',
          dataIndex: 'szbm',
        },
      ],
      VIPdata:[
        {
          name:'必填',
          sex:'必填',
          sfzh:'必填',
          birthday:'必填',
          province:'必填',
          city:'非必填',
          unit:'非必填',
          szbm:'非必填',
        }
      ],
      activeKey: ['1'],
      loadImg:false,
      changeFile:'',
      importModal:false,
      title:'添加',
      spinning:false,
      visible:false,
      addRules: {
        startTime: [{ required: true, message: "请选择", trigger: "change" }],
        meeting: [{ required: true, message: "请选择", trigger: "change" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      addRulesTask: {
        startTime: [{ required: true, message: "请选择", trigger: "change" }]
      },
      form:{
        meeting:undefined,
        startTime:'',
        endTime:'',
        title:'',
        direct:[],
      },
      visibleTask:false,
      scheduleList:{},
      titleTask:'添加',
      formTask:{
        startTime:null,
        endTime:null,
        title:'',
        expertId:undefined,
      },
      columns,
      isSelectDate:null,
      isSelectPlace:null,
      data:[],
      startTime:null,
      endTime:null,
      dateList:[],
      addressList:[],

      addressVal:'',
      addressId:null,
      visibleAddress:false,
      columnsAddress:[
        {
          title: '会场名称',
          key: 'address',
          dataIndex: 'address',
        },{
          title: '排序',
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      addressAction:'添加', //区分会场操作是添加还是修改
      selectExpertTitle:'',
      expert_select_page:1,
      expertList:[],
      expertListCount:0,
      FirstBeginTime:null,
      FirstEndTime:null,
    }
  },

  computed: {
    tableData() {
      const seenTitles = new Set(); // 用于跟踪已见的标题
      return this.data.map(item => {
        if (seenTitles.has(item.siteName)) {
          return {
            academicList: item.academicList,
            beginTime: item.beginTime,
            endTime: item.endTime,
            expertList: item.expertList,
            id: item.id,
            siteId: item.siteId,
            siteName: null,
            title: item.title,
          }; // 重复标题置为 null
        } else {
          seenTitles.add(item.siteName);
          return item; // 保留原项
        }
      });
    }
  },

  methods:{
    async changeFileElsx() {
      this.loadImg = true
      let inputDOM = this.$refs.uploadFile_inputFile.files[0];
      let formData = new FormData()
      formData.append('file', inputDOM)
      this.changeFile = inputDOM.name
      this.exportformData = formData
      this.loadImg = false
    },
    openExport(){
      this.importModal = true;
    },
    async importOk() {
      if (this.exportformData) {
        const response = await scheduleImportRcExcel(this.code, this.exportformData)
        if (response.code === 200) {
          //上传成功后将value制空防止上传一样名称文件时不触发change
          document.getElementById('uploadFile').value = null;
          this.$message.success(response.message);
          await this.getScheduleList();
          this.changeFile = ''
        } else {
          this.$message.warning(response.message)
          document.getElementById('uploadFile').value = null;
          this.exportformData = ''
          this.changeFile = ''
        }
      } else {
        this.$message.warning("请先选择文件~")
      }
    },
    async cancelImport(){
      document.getElementById('uploadFile').value = null;
      this.exportformData = ''
      this.changeFile = ''
    },
    async downloadTemplate() {
      let data = {
        ExelName: '会议日程导入模板'+new Date().getTime(),
      }
      const info = await exportMb(data)
    },
    importClose(){
      this.importModal = false
    },
    moment,
    async toLeadBtn(event) {
      const file = event.target.files[0];
      let formData = new FormData()
      formData.append('file', file)
      const info = await scheduleImportRcExcel(this.code, formData)
      if (info.code === 200) {
        this.$message.success(info.message);
        await this.getScheduleList();
      }else {
        this.$message.warning(info.message);
      }
    },
    //获取专家列表
    async getLogData(){
      const data={
        name:this.selectExpertTitle,
        // office:this.office,
        page:this.expert_select_page,
        pageSize:10,
      }
      const info = await  confereeSelectAll(this.code,data)
      if (info.code === 200){
        // this.expertList = info.data;
        this.expertListCount = info.count;
        let list  = info.data;
        for (let i=0;i<list.length;i++){
          // 检查数组中是否已有同样id的对象
          if (!this.expertList.some(item => item.id === list[i].id)) {
            this.expertList.push(list[i]); // 如果没有，则添加
          }
          // this.expertList.push(list[i]);
        }

      }
    },
    //滚动条监听
    popupScroll_expert(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        if(this.expert_select_page * 10 < this.expertListCount){
          this.expert_select_page++;
          this.getLogData();
        }
      }
    },
    select_expert(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      if(this.selectExpertTitle !== value){
        this.expertList = [];
        this.expert_select_page = 1;
        this.selectExpertTitle = value;
        if(this.expertList.length === 0){
        this.getLogData();
        }
      }
    },

    //获取日期列表
    async getDateList() {
      const response = await getSelectDate(this.code);
      if (response.code === 200) {
        this.dateList = response.data;
        if(response.data.length){
          this.isSelectDate = response.data[0];
        }
      }
    },
    //获取会场列表
    async getAddressList() {
      const response = await getSelectSite(this.code);
      if (response.code === 200) {
        this.addressList = response.data;

        if(response.data.length === 1){
          this.isSelectPlace = response.data[0].id;
        }
        if(response.data.length > 1){
          this.isSelectPlace = '';
        }

      }
    },
    //添加会场列表
    async postAddressList(data) {
      const response = await postAddressSchedule(data);
      if (response.code === 200) {
        this.$message.success("添加成功!");
        this.addressAction = '添加';
        this.addressId = null;
        this.addressVal = '';
        await this.getAddressList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改会场列表
    async putAddressList(data) {
      const response = await putAddressSchedule(data);
      if (response.code === 200) {
        this.$message.success("修改成功!");
        this.addressAction = '添加';
        this.addressId = null;
        this.addressVal = '';
        await this.getAddressList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改会场列表上移下移
    async putAddressListMove(type,id) {
      const response = await putAddressScheduleMove(type,id);
      if (response.code === 200) {
        this.$message.success("操作成功!");
        await this.getAddressList();
      }else {
        this.$message.warning(response.message)
      }
    },
    delBtn(row){
      let data = {
        id:row.id
      }
      this.delAddressList(data);
    },
    //删除会场列表
    async delAddressList(data) {
      const response = await delAddressSchedule(data);
      if (response.code === 200) {
        this.$message.success("修改成功!")
        await this.getAddressList();
      }else {
        this.$message.warning(response.message)
      }
    },

    //获取会议日程
    async getScheduleList() { //code,date,siteId
      this.spinning = true;
      let data = {
        siteId:this.isSelectPlace
      }
      const response = await getSelectSchedule(this.code,this.isSelectDate,data);
      if (response.code === 200) {
        this.data = response.data;
      }
      this.spinning = false;
    },
    /**

    "conventionCode": "YwAUpL",//会议code
    "siteId":1,//地点
    "title": "青年科学家论坛专题会1",//日程名称
    "beginTime": "2024-09-27 15:23:28",//日程开始时间
    "endTime": "2024-09-27 15:23:33",//日程结束时间
    "chairmanIdList": [1,2,3]//专家id 组成这样的json


     *
     *
     * @param data
     * @returns {Promise<void>}
     */
    //添加一级日程
    async postScheduleList(data) {
      const response = await postSelectSchedule(data);
      if (response.code === 200) {
        this.$message.success("添加成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改一级日程
    async putScheduleList(data) {
      const response = await putSelectSchedule(data);
      if (response.code === 200) {
        this.$message.success("修改成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //删除一级日程
    async delScheduleList(data) {
      const response = await delSelectSchedule(data);
      if (response.code === 200) {
        this.$message.success("删除成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },



    //添加一级日程
    async postScheduleAcademicList(data) {
      const response = await postSelectScheduleAcademic(data);
      if (response.code === 200) {
        this.$message.success("添加成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改一级日程
    async putScheduleAcademicList(data) {
      const response = await putSelectScheduleAcademic(data);
      if (response.code === 200) {
        this.$message.success("修改成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //删除一级日程
    async delScheduleAcademicList(data) {
      const response = await delSelectScheduleAcademic(data);
      if (response.code === 200) {
        this.$message.success("删除成功!");
        await this.getScheduleList();
      }else {
        this.$message.warning(response.message)
      }
    },



    editAddress(row){
      this.addressAction = '修改';
      this.addressId = row.id;
      this.addressVal = row.address;
    },
    addAddress(){
      if(this.addressVal){
        let data;
        if(this.addressAction === '添加'){
          data = {
            "conventionCode": this.code,
            "address": this.addressVal //会场名称
          }
          this.postAddressList(data);
        }else if(this.addressAction === '修改'){
          data = {
            "id": this.addressId,
            "address": this.addressVal //会场名称
          }
          this.putAddressList(data);
        }
      }else {
        this.$message.warning("请输入会场名称！")
      }
    },
    openAddressModel(){
      this.addressAction = '添加';
      this.addressId = null;
      this.addressVal = '';
      this.visibleAddress = true;
    },
    onClose(){
      this.visibleAddress = false;
    },
    addBtn(title){
      this.title = title;
      this.startTime = moment('08:00', 'HH:mm');
      this.form.startTime = '08:00';
      this.form.meeting = this.isSelectPlace ? this.isSelectPlace :undefined;
      this.expert_select_page = 1;
      this.selectExpertTitle = '';
      this.getLogData();
      this.visible = true;
    },
    async ExportTemplate() {
        let data = {
        ExelName: '导入会议日程模板'+new Date().getTime()
      }
      const res = await exportMb(data)
      // const header = headerConfig.api_header;
      // window.open(header + '/conferenceAffairs/schedule/exportMb')
    },
    addBtnTask(table){
      //添加二级日程时间范围为一级日程的开始结束时间
      //一级日程的开始结束时间
      //一级日程开始结束时间
      this.FirstBeginTime = table.beginTime.substring(11, 16);
      this.FirstEndTime = table.endTime.substring(11, 16);

      this.scheduleList = table;
      this.title = '添加';
      this.startTime = moment(table.beginTime.substring(11, 16), 'HH:mm');
      this.formTask.startTime = table.beginTime.substring(11, 16);
      this.expert_select_page = 1;
      this.selectExpertTitle = '';
      this.getLogData();
      this.visibleTask = true;
    },
    selectStartTime(time) {
      this.form.startTime = moment(time).format('HH:mm');
    },
    blurStartTime(open){
      if(!open){
        this.endTime = moment(this.form.startTime, 'HH:mm');
      }
    },
    blurStartTimeTask(open){
      if(!open){
        this.endTime = moment(this.formTask.startTime, 'HH:mm');
      }

    },
    selectEndTime(time) {
      this.form.endTime = moment(time).format('HH:mm');
    },
    selectStartTimeTask(time) {
      this.formTask.startTime = moment(time).format('HH:mm');
    },
    selectEndTimeTask(time) {
      this.formTask.endTime = moment(time).format('HH:mm');
    },
    handleChange(){

    },
    addSubmit(type){ //提交后取消
      this.$refs.ruleForm_add.validate((valid) => {
        if (valid) {
          if(this.form.endTime){
            let data;
            if(this.title === '添加'){ //新增
              data = {
                "conventionCode": this.code,//会议code
                "siteId":this.form.meeting,//地点
                "title": this.form.title,//日程名称
                "beginTime": this.isSelectDate+' '+this.form.startTime,//日程开始时间
                "endTime": this.isSelectDate+' '+this.form.endTime,//日程结束时间
                "chairmanIdList": this.form.direct//专家id 组成这样的json
              }
              this.postScheduleList(data);
            }
            else if(this.title === '修改'){
              data = {
                "id": this.form.id,//一级日程id
                "title": this.form.title,//日程名称
                "siteId":this.form.meeting,//地点
                "beginTime": this.isSelectDate+' '+this.form.startTime,//日程开始时间
                "endTime": this.isSelectDate+' '+this.form.endTime,//日程结束时间
                "chairmanIdList": this.form.direct//专家id 组成这样的json
              }
              this.putScheduleList(data);
            }
            if(type !== 'nuClose'){
              this.addClose();
            }else {
              this.addSubmitNoClose();
            }
          }else {
            this.$message.warning("请先选择时间！")
          }


        } else {
          return false;
        }
      });

    },
    addSubmitNoClose(){ //保存并继续添加
      this.form = {
        meeting:this.form.meeting,
        startTime:'',
        endTime:'',
        title:'',
        direct:[],
      }
      this.startTime = moment('08:00', 'HH:mm');
      this.form.startTime = '08:00';
      this.endTime = null;
    },

    confirmSchedule(){
      let data = {
        "id": this.form.id //一级日程id
      }
      this.delScheduleList(data);
      this.addClose();
    },
    addSubmitTaskNoClose(){ //保存并继续添加
      this.formTask = {
        startTime:null,
        endTime:null,
        title:'',
        expertId:undefined,
      }
      this.startTime = moment('08:00', 'HH:mm');
      this.formTask.startTime = '08:00';
      this.endTime = null;
    },
    confirmTask(){
      let data = {
        "id": this.formTask.id //一级日程id
      }
      this.delScheduleAcademicList(data);
      this.addCloseTask()
    },
    addClose(){
      this.form = {
        meeting:undefined,
        startTime:'',
        endTime:'',
        title:'',
        direct:[],
      }
      this.startTime = moment('08:00', 'HH:mm');
      this.form.startTime = '08:00';
      this.endTime = null;

      this.expert_select_page = 1;
      this.selectExpertTitle = '';
      this.expertList = [];
      this.visible = false;
      this.$refs.ruleForm_add.resetFields();
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            //循环获取讲者主持id
            let direct = [];
            if(record.expertList){
              if(record.expertList.length){
                record.expertList.forEach((list)=>{
                  direct.push(list.id)
                })
              }
            }

            this.title = '修改';
            this.form = {
              id:record.id,
              meeting:record.siteId,
              startTime:record.beginTime.slice(11),
              endTime:record.endTime.slice(11),
              title:record.title,
              direct:direct,
            }


            this.startTime = moment(record.beginTime.slice(11), 'HH:mm');
            this.endTime = moment(record.endTime.slice(11), 'HH:mm');

            this.expert_select_page = 1;
            this.selectExpertTitle = '';
            this.getLogData();

            this.visible = true;
          }
        }
      }
    },
    rowClickChild(record, index) {
      return {
        on: {
          click: () => {
            //一级日程开始结束时间
            this.FirstBeginTime = record.sBeginTime.substring(11, 15);
            this.FirstEndTime = record.sEndTime.substring(11, 15);

            //循环获取讲者主持id
            let direct = [];
            if(record.expertList){
              if(record.expertList.length){
                record.expertList.forEach((list)=>{
                  direct.push(list.id)
                })
              }
            }


            this.scheduleList.title = record.scheduleTitle; //父级标题
            this.title = '修改';
            this.formTask = {
              id:record.id,
              startTime:record.beginTime.slice(11),
              endTime:record.endTime.slice(11),
              title:record.title,
              direct:record.expertList
            }

            this.startTime = moment(record.beginTime.slice(11), 'HH:mm');
            this.endTime = moment(record.endTime.slice(11), 'HH:mm');

            this.expert_select_page = 1;
            this.selectExpertTitle = '';
            this.expertList = [];
            this.getLogData();

            this.visibleTask = true;
          }
        }
      }
    },
    selectDteBtn(item){
      this.isSelectDate = item;
      this.getScheduleList();
    },
    selectPlaceBtn(index){
      this.isSelectPlace = index;
      this.getScheduleList();
    },
    getWeekdayInChinese(dateStr) {
      const date = new Date(dateStr);
      const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

      // 检查日期是否有效
      if (isNaN(date.getTime())) {
        return '无效的日期';
      }

      // 获取星期几的索引并返回对应的中文星期几
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    },

    //----------------------日程-------------------
    //获取不可选择的小时
    disabledStartHours(){
      let endTime = moment(this.endTime).format('HH:mm')
      if(endTime){
        let hours = [];
        let hour = Number.parseInt(endTime.substring(0, 2));
        for (let i = hour+1; i < 24; i++) {
          hours.push(i);
        }
        return hours
      }
    },
    //获取不可选择的分钟
    disabledStartMinutes(selectedHour){
      let endTime = moment(this.endTime).format('HH:mm')
      if(endTime){
        let minutes = [];
        let hour = Number.parseInt(endTime.substring(0, 2));
        let minute = Number.parseInt(endTime.substring(3, 5));
        if(selectedHour>=hour){
          for (let i = minute+1; i < 60; i++) {
            minutes.push(i);
          }
        }
        return minutes
      }
    },
    //获取不可选择的小时
    disabledEndHours(){
      let startTime = moment(this.startTime).format('HH:mm')
      if(startTime){
        let hours = [];
        let hour = Number.parseInt(startTime.substring(0, 2));
        for (let i = 0; i < hour; i++) {
          hours.push(i);
        }
        return hours
      }
    },
    //获取不可选择的分钟
    disabledEndMinutes(selectedHour){
      let startTime = moment(this.startTime).format('HH:mm')
      if(startTime){
        let minutes = [];
        let hour = Number.parseInt(startTime.substring(0, 2));
        let minute = Number.parseInt(startTime.substring(3,5));
        if(selectedHour<=hour){
          for (let i = 0; i < minute; i++) {
            minutes.push(i);
          }
        }
        return minutes
      }
    },



    //----------------------任务（二级日程）------------------
    //获取不可选择的小时
    disabledStartHoursSecond(){
      //取一级日程的开始时间和结束时间中的小时，且大于结束时间
      let FirstBeginTime = this.FirstBeginTime;
      let FirstEndTime;

      if(this.endTime){ //又开始时间就取开始时间，没有开始时间就取一级日程开始时间
        FirstEndTime = moment(this.endTime).format('HH:mm');
      }else {
        FirstEndTime = this.FirstEndTime;
      }

      if(FirstBeginTime && FirstEndTime){
        let numbers = []; //禁选小时
        let sHour = Number.parseInt(FirstBeginTime.substring(0, 2));
        let eHour = Number.parseInt(FirstEndTime.substring(0, 2));

        // 添加 1 到 sHour-1
        for (let i = 0; i <= sHour-1; i++) {
          numbers.push(i);
        }

        // 添加 eHour+1 到 24
        for (let j = eHour+1; j <= 24; j++) {
          numbers.push(j);
        }

        return numbers
      }

    },
    //获取不可选择的分钟
    disabledStartMinutesSecond(selectedHour){
      let FirstBeginTime = this.FirstBeginTime;
      let FirstEndTime;

      if(this.endTime){ //又开始时间就取开始时间，没有开始时间就取一级日程开始时间
        FirstEndTime = moment(this.endTime).format('HH:mm');
      }else {
        FirstEndTime = this.FirstEndTime;
      }

      if(FirstBeginTime && FirstEndTime){
        let minutes = [];
        let sHour = Number.parseInt(FirstBeginTime.substring(0, 2));
        let eHour = Number.parseInt(FirstEndTime.substring(0, 2));

        let sminute = Number.parseInt(FirstBeginTime.substring(3, 5));
        let eminute = Number.parseInt(FirstEndTime.substring(3, 5));

        // console.log('一样一样？？',selectedHour === sHour && sHour === eHour)
        if(selectedHour === sHour  && sHour === eHour){

          let gminute = Number.parseInt(this.FirstEndTime.substring(3, 5));
          // console.log('一样一样？？',sminute ,eminute , gminute)
          for (let i = 0 ; i < sminute; i++) {
            console.log(i)
            minutes.push(i);
          }
          for (let j = gminute +1 ; j < 60; j++) {
            minutes.push(j);
          }



        }else {
          if(selectedHour === sHour){
            for (let i = 0; i < sminute; i++) {
              minutes.push(i);
            }
          }
          else if (selectedHour === eHour){
            for (let i = eminute+1; i < 60; i++) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      }

    },
    //获取不可选择的小时
    disabledEndHoursSecond(){

      //取一级日程的开始时间和结束时间中的小时，且大于结束时间
      let FirstBeginTime;
      let FirstEndTime = this.FirstEndTime;

      if(this.startTime){ //又开始时间就取开始时间，没有开始时间就取一级日程开始时间
        FirstBeginTime = moment(this.startTime).format('HH:mm');
      }else {
        FirstBeginTime = this.FirstBeginTime;
      }

      if(FirstBeginTime && FirstEndTime){
        let numbers = []; //禁选小时
        let sHour = Number.parseInt(FirstBeginTime.substring(0, 2));
        let eHour = Number.parseInt(FirstEndTime.substring(0, 2));

        // 添加 1 到 sHour-1
        for (let i = 0; i <= sHour-1; i++) {
          numbers.push(i);
        }

        // 添加 eHour+1 到 24
        for (let j = eHour+1; j <= 24; j++) {
          numbers.push(j);
        }

        return numbers
      }

      // let startTime = moment(this.startTime).format('HH:mm')
      // if(startTime){
      //   let hours = [];
      //   let hour = Number.parseInt(startTime.substring(0, 2));
      //   for (let i = 0; i < hour; i++) {
      //     hours.push(i);
      //   }
      //   return hours
      // }
    },
    //获取不可选择的分钟
    disabledEndMinutesSecond(selectedHour){

      let FirstBeginTimeEnd;
      let FirstEndTimeEnd = this.FirstEndTime;

      if(this.startTime){ //选择了开始时间就取开始时间，没有开始时间就取一级日程开始时间
        FirstBeginTimeEnd = moment(this.startTime).format('HH:mm');
      }else {
        FirstBeginTimeEnd = this.FirstBeginTime;
      }


      if(FirstBeginTimeEnd && FirstEndTimeEnd){
        let minutes = [];
        let sHour = Number.parseInt(FirstBeginTimeEnd.substring(0, 2));
        let eHour = Number.parseInt(FirstEndTimeEnd.substring(0, 2));

        let sminute = Number.parseInt(FirstBeginTimeEnd.substring(3, 5));
        let eminute = Number.parseInt(FirstEndTimeEnd.substring(3, 5));

        // console.log('一样一样？？',selectedHour === sHour && sHour === eHour)
        if(selectedHour === sHour  && sHour === eHour){

          // let gminute = Number.parseInt(this.FirstBeginTime.substring(3, 5));
          // for (let i = gminute+1 ; i < 60; i++) {
          //   minutes.push(i);
          // }

          let gminute = Number.parseInt(this.FirstEndTime.substring(3, 5));
          // console.log('一样一样？？',sminute ,eminute , gminute)
          for (let i = 0 ; i < sminute; i++) {
            minutes.push(i);
          }
          for (let j = gminute +1 ; j < 60; j++) {
            minutes.push(j);
          }


        }else {
          if(selectedHour === sHour){
            for (let i = 0; i < sminute; i++) {
              minutes.push(i);
            }
          }
          else if (selectedHour === eHour){
            for (let i = eminute+1; i < 60; i++) {
              minutes.push(i);
            }
          }
        }

        return minutes;
      }

      // let startTime = moment(this.startTime).format('HH:mm')
      // if(startTime){
      //   let minutes = [];
      //   let hour = Number.parseInt(startTime.substring(0, 2));
      //   let minute = Number.parseInt(startTime.substring(3,5));
      //   if(selectedHour<=hour){
      //     for (let i = 0; i < minute; i++) {
      //       minutes.push(i);
      //     }
      //   }
      //   return minutes
      // }
    },


    addSubmitTask(type){
      this.$refs.ruleForm_Task.validate((valid) => {
        if (valid) {
          if(this.formTask.endTime){
            let data;
            if(this.title === '添加'){ //新增
              data = {
                "scheduleId": this.scheduleList.id, //一级日程id
                "title": this.formTask.title, //二级日程名称
                "beginTime": this.isSelectDate+' '+this.formTask.startTime, //开始时间
                "endTime": this.isSelectDate+' '+this.formTask.endTime, //结束时间
                "expertId":this.formTask.direct//专家id
              }
              this.postScheduleAcademicList(data);
            }else if(this.title === '修改') { //修改
              data = {
                "id": this.formTask.id, //二级日程id
                "title": this.formTask.title, //二级日程名称
                "beginTime": this.isSelectDate+' '+this.formTask.startTime, //开始时间
                "endTime": this.isSelectDate+' '+this.formTask.endTime, //结束时间
                "expertId":this.formTask.direct//专家id
              }
              this.putScheduleAcademicList(data);
            }
            if(type !== 'nuClose'){
              this.addCloseTask();
            }else {
              this.addSubmitTaskNoClose();
            }

          }else {
            this.$message.warning("请先选择时间！")
          }
        } else {
          return false;
        }
      });

    },
    addCloseTask(){
      this.formTask = {
        startTime:null,
        endTime:null,
        title:'',
        expertId:undefined,
      }
      this.startTime = moment('08:00', 'HH:mm');
      this.formTask.startTime = '08:00';
      this.endTime = null;

      this.expert_select_page = 1;
      this.selectExpertTitle = '';
      this.expertList = [];
      this.visibleTask = false;
    },
    setRowClassName() {
      return 'custom-row'; // 返回自定义行类名
    },
    setRowClassNameTwo(){
      return 'custom-row-Two'; // 返回自定义行类名
    },

    dateChinese(date){
      return moment(date).format('MM月DD日')
    }
  }

}
</script>

<style lang="scss" scoped>
.custom-file .file-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
.uploadImg::before {
  content: '选择文件';
  position: absolute;
  right:-50px;
  display: inline-block;
  background: #fff;
  color: white;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  white-space: nowrap;
}
.menuList{
  margin-top:10px;
}
.menuList ::v-deep .ant-menu-submenu-title {
  background-color: #e6f7ff;
  border-radius: 5px;
  border:1px solid #a0dbff !important;
  span{
    color:#5992d6;
    margin-left:10px
  }
}

.menuList ::v-deep .ant-menu-submenu-title span {
  // span{
  color:#5992d6;
  margin-left:10px
  // }
}
.menuList ::v-deep .ant-menu-submenu-arrow{
  left:18px;
  color:#b8c0cd
}
.menuList p{
  color:black;
  font-size:13px
}
.menuList ::v-deep .ant-menu-item-group-title{
  padding-left:18px

}

::v-deep .ant-collapse > .ant-collapse-item{
  background-color: #e6f7ff;
  border:1px solid #a0dbff !important;
}
::v-deep .ant-collapse-header{

}
::v-deep .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
  color:#5992d6;
  line-height:10px
}
::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
  color:#b5beca
}
.collapse p{
  font-size:13px;
  color:black
}

.blueTable{
  ::v-deep .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{
    font-weight: bold;
  }
  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  ::v-deep  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr{
    background-color: #9bc2e6;
  }
}

</style>

<style lang="scss">
.custom-row{
  background-color: #5DC8FD;
}
.custom-row-Two{
  padding: 0;
  margin: 0;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  padding: 10px!important;
}

.tableClass{
  //background: #f4742f !important;
  background-color: #f4742f!important;
  color: red!important;
}

.indexBox{
    background-color: #fff;
    min-height: 50vh;
    .MeetingSchedule{
      min-height: 120px;
      padding:0 20px;
      border-bottom: 10px solid #EDEFF2;
      .date{
        width: 100%;
        min-height: 50px;
        border-bottom: 1px dashed #9c9b9b;
        display: flex;
        align-items: center;
        padding-top: 10px;
        //padding-bottom: 10px;
        .date-title{
          min-width: 80px;
          display: flex;
          align-items: center;
          color: #333333;
          margin-top: -10px;
        }
        .date-list{
          .listView{
            width: 85px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 5px;

            margin-right: 10px;
            margin-bottom: 10px;
            float: left;
            cursor: pointer;
          }
        }
        .NoSelect{
          color: #333333;
        }
        .isSelect{
          color: white;
          background-color: #45A5E6;
        }
      }
      .meetingPlace{
        width: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-top: 10px;
        //padding-bottom: 10px;
        .meetingPlace-title{
          min-width: 80px;
          display: flex;
          align-items: center;
          color: #333333;
          margin-top: -10px;
        }
        .set-title{
          width: 60px;
          display: flex;
          align-items: center;
          color: #333333;
          margin-top: -10px;
          //padding-top: 14px;
          padding-top: 4px;
          background-color: #f4742f;
        }
        .meetingPlace-list{

          .meetingPlaceListView{
            width: 85px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 5px;

            margin-right: 10px;
            margin-bottom: 10px;
            float: left;
            cursor: pointer;

          }
        }
        .NoSelect{
          color: #333333;
        }
        .isSelect{
          color: white;
          background-color: #45A5E6;
        }
      }
    }
    .schedule{
      width: 100%;
      height: 50px;
      padding:0 20px;
      border-bottom:1px solid #edeff2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3{
        font-weight:bold;
        margin-bottom:0
      }
    }
  .NoList{
    color: #b1b1b1;
    width: 100%;
    text-align: center;
    margin-top: 150px;
  }
  .scheduleList{
    padding: 20px;
    .scheduleListHeader{
      background-color: #EEEEEE;
      height: 40px;
      display: flex;
      border: 1px solid #E8E8E8;
      color: #333333;
      font-weight: bold;
      .headerMid{
        width: 33.33%;padding: 10px;border-left: 1px solid #E8E8E8;border-right: 1px solid #E8E8E8;
      }
    }

    .siteName{
      width: 100%;
      height: 40px;
      border: 1px solid #d4d4d4;
      //text-align: center;
      line-height: 40px;
      cursor: pointer;
      padding-left: 20px;
      background-color: #004b7d;
      color: white;
    }
    .addBtn{
      width: 100%;
      height: 40px;
      border: 1px solid #d4d4d4;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

.upload_2File{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top:15px ;
}
</style>
