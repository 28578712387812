<template>
  <!-- 会议管理 -->
  <div style="border-top: 1px solid #edeff2; border-left: 1px solid #edeff2">
    <div style="background-color: #edeff2; height: 100vh">
      <div class="firstBox">
        <div>
          <h2 style="font-weight: bold; margin-bottom: 18px; margin-top: 16px">
            {{ resData.title }}
          </h2>
          <span style="margin-right: 25px; display: inline-block">创建人：{{ resData.name }}</span>
          <span>创建时间：{{ resData.createdTime.substring(0, 10) }}</span>
          <a-tabs v-model="tabValue" class="tab">
            <a-tab-pane key="1" tab="会议计划"></a-tab-pane>
            <a-tab-pane key="3" tab="会议日程"></a-tab-pane>
            <a-tab-pane key="2" tab="参会人员"></a-tab-pane>
<!--            <a-tab-pane key="4" tab="电子物料"></a-tab-pane>-->
          </a-tabs>
        </div>
        <div style="display: flex; algin-items: center">
          <div class="qrCode">
            <img
              src="../../../assets/会务助手二维码-正式.png"
              style="width: 100px; height: 100px"
              v-if="qrCode == 'formal'"
            />
            <img
              src="../../../assets/会议助手code_测试.png"
              style="width: 100px; height: 100px"
              v-if="qrCode == 'ceShi'"
            />

            <span style="font-size: 12px">会议助手二维码</span>
          </div>
          <div style="position: relative">
            <a-progress
              type="circle"
              :percent="
                (resData.haveFinishedCount / resData.allDetailTaskCount) * 100
              "
              style="white-space: pre"
              :format="
                (percent) =>
                  ` ` +
                  '\n' +
                  resData.haveFinishedCount +
                  `/` +
                  resData.allDetailTaskCount
              "
              :strokeWidth="strokeWidth"
              strokeColor="#fbda55"
              strokeLinecap="square"
              :width="130"
            />
            <p
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -28px;
                margin-top: -24px;
              "
            >
              计划进展
            </p>
            <!-- <a-progress type="circle" :percent="100" /> -->
            <!-- <p> </p> -->
          </div>
        </div>
      </div>
<!--      日程-->
      <div class="componentBox" v-if="tabValue === '3'">
        <MeetingSchedule :code="code" />
      </div>
<!--      参会人员-->
      <div class="componentBox" v-if="tabValue === '2'">
        <Participants :conventionCode="code" />
      </div>
<!--      会议计划-->
      <div class="componentBox" v-if="tabValue === '1'">
        <taskAllocation
          @getTop="getTop"
          :code="code"
          :conventionId="conventionId"
        ></taskAllocation>
      </div>
<!--      电子物料-->
     <div class="componentBox" v-if="tabValue === '4'">
      <ElectronicMaterial  :conventionId="conventionId" :HYtitle="HYtitle"/>
    </div>
    </div>
  </div>
</template>

<script>
import taskAllocation from "@/components/ManagementOfMeetings/taskAllocation";
import ElectronicMaterial from "@/components/ManagementOfMeetings/ElectronicMaterial";
import MeetingSchedule from "@/components/ManagementOfMeetings/MeetingSchedule";
import Participants from "@/components/ManagementOfMeetings/ParticipantsNew";
import { getTopDetail } from "@/service/managementOfMeetings";
import headerConfig from "@/service/api_header_config";
export default {
  components: {
    taskAllocation,
    MeetingSchedule,
    Participants,
    ElectronicMaterial
  },
  data() {
    return {
      headerConfig: headerConfig,
      tabValue: "1",
      code: "",
      strokeWidth: 12,
      conventionId: "",
      resData: {},
      qrCode: "",
      HYtitle: ""

    };
  },
  created() {
    this.code = this.$route.query.code;
    this.conventionId = this.$route.query.id;


    if (headerConfig.api_header == "https://api.yichimeeting.com") {
      this.qrCode = "formal";
    } else {
      this.qrCode = "ceShi";
    }
  },
  mounted() {
    this.getTop();


  },
  methods: {
    async getTop() {
      const res = await getTopDetail(this.conventionId);
      if (res.code == 0) {
        this.resData = res.data;
        this.HYtitle = res.data.title;

      }
    },
  },
};
</script>

<style scoped lang="scss">
.firstBox {
  /* background-color: pink; */
  z-index: 999;
  background-color: white;
  padding: 0px 50px 0px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ::v-deep .ant-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #272727;
    line-height: 1;
    text-align: center;
    transform: translate(-50%, -50%);
    white-space: pre-wrap !important; //提高指定样式规则的应用优先权（优先级）
    font-size: 25px;
    // font-weight:bold
  }
}
.tab {
  padding: 0 20px;
  background-color: white;
  width: 500px;
  margin-top: 20px;
  ::v-deep .ant-tabs-bar {
    border-bottom: 0px;
  }
}
.componentBox {
  //background-color: #edeff2;
  // padding:15px 20px;
  /* height: 100vh; */
  box-sizing: border-box;
  border-width: 20px;
  border-color: #edeff2;
  border-style: solid;
}
::v-deep .ant-tabs-bar {
  margin-bottom: 0;
}
.ant-progress-circle-wrap,
.ant-progress-line-wrap {
  margin-right: 8px;
  margin-bottom: 5px;
}
.qrCode {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
