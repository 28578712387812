<template>
<div>
<!-- 住宿酒店管理  -->
  <a-modal
      :title="title"
      :visible="visible"
      @ok="okBtn"
      @cancel="cancelBtn"
      width="850px"
  >
    <div class="ConferenceHotel">
      <div>
        <a-button @click="openModel" type="primary" style="margin:0 0 20px 0">+ 新增</a-button>
        <a-table :columns="columns" :data-source="data" :pagination="false">
          <span slot="serialNumber" slot-scope="text, record">
            1
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="editBtn" style="margin-right: 10px">修改</a>
            <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delBtn"
            ><a href="#">删除</a></a-popconfirm>
          </span>
        </a-table>
        <div class="pagination">
          <a-pagination show-quick-jumper :default-current="current" :total="total" @change="onChange" />
        </div>
      </div>
    </div>
  </a-modal>

  <a-modal
      :title="titleModel"
      :visible="visibleModel"
      @ok="okModelBtn"
      @cancel="cancelModelBtn"
      width="400px"
  >
    <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rulesModel"
        :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="酒店名称" prop="hotelName">
            <a-input v-model="formModel.hotelName" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="酒店地址" prop="hotelAddress">
            <a-input v-model="formModel.hotelAddress" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="酒店联系人" prop="HotelContact">
            <a-input v-model="formModel.HotelContact" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="联系人电话" prop="phone">
            <a-input v-model="formModel.phone" placeholder="请输入" style="width: 230px"/>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</div>
</template>

<script>
export default {
name: "HotelManagement",
  props:{
    visible:{
      type:Boolean,
    },
    title:{
      type:String
    }
  },
  data(){
  return{
    columns:[
      {
        title: '酒店名称',
        dataIndex: 'hotelName',
        key: 'hotelName',
      },
      {
        title: '酒店地址',
        dataIndex: 'hotelAddress',
        key: 'hotelAddress',
      },
      {
        title: '酒店联系人',
        key: 'HotelContact',
        dataIndex: 'HotelContact',
      },
      {
        title: '联系人电话',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    data:[],
    total:0,
    current:1,

    visibleModel:false,
    formModel:{},
    rulesModel:{
      hotelName: [{ required: true, message: '请输入', trigger: 'blur' }],
      hotelAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
      HotelContact: [{ required: true, message: '请输入', trigger: 'blur' }],
      phone: [{ required: true, message: '请输入', trigger: 'blur' }],
    },
    titleModel:'',
  }
  },
  methods:{
    cancelBtn(){
      console.log(1111111)
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值(开关)
    },
    onChange(page,pageSize){
      this.current = page;
    },
    delBtn(){

    },
    okBtn(){

    },
    editBtn(){

    },
    cancelModelBtn(){
      this.visibleModel = false;
    },
    okModelBtn(){

    },
    openModel(){
      this.titleModel = '新增';
      this.visibleModel = true;
    },
  }
}
</script>

<style scoped lang="scss">
 .ConferenceHotel{
   font-weight: bold;
   color: #333333;
 }
 .ConferenceHotel-form{
   margin-bottom: 20px;
   border-bottom: 1px solid #e0e0e0;
 }
 .pagination{
   width: 100%;
   display: flex;
   justify-content: right;
   margin-top: 10px;
 }
</style>
