<template>
  <div class="ParticipantsNew">
    <a-spin :spinning="spinning">
    <div class="header">
      <div style="display: flex;justify-content: center;align-items: center">
        <a-input-search
            style="width: 230px;
            margin-right: 15px"
            v-model="name"
            allowClear
            placeholder="请输入姓名" @search="onSearch">
          <a-button type="primary" slot="enterButton">
            搜索
          </a-button>
        </a-input-search>
        <div style="display: flex">
          <div style="margin-right: 15px">
            负责人：
            <a-select
                allowClear
                show-search
                v-model="tmId"
                placeholder="请选择"
                style="width: 200px"
                @change="handleChangeTmId"
                optionFilterProp="label"
            >

              <a-select-option :value="null">
                全部
              </a-select-option>
              <a-select-option v-for="item in principalData" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
<!--          <div>-->
<!--            邀请状态：-->
<!--            <a-select-->
<!--                v-model="status"-->
<!--                placeholder="请选择"-->
<!--                style="width: 200px"-->
<!--                @change="handleChangestatus"-->
<!--            >-->
<!--&lt;!&ndash;             状态0待邀请 1待回复 2已拒绝 3已接收 &ndash;&gt;-->
<!--              <a-select-option value="">全部</a-select-option>-->
<!--              <a-select-option :value="0">待邀请</a-select-option>-->
<!--              <a-select-option :value="1">待回复</a-select-option>-->
<!--              <a-select-option :value="2">已拒绝</a-select-option>-->
<!--              <a-select-option :value="3">已接收</a-select-option>-->
<!--            </a-select>-->
<!--          </div>-->
        </div>
      </div>
      <div style="margin-right: 5px">
<!--        <a-button>导入</a-button>-->
        <a-button style="margin: 0 5px" @click="deleteBatches" v-if="selectedRowKeys.length">批量删除</a-button>
<!--        <a-button style="margin: 0 5px" @click="deriveBtn">导出</a-button>-->
        <a-button style="margin: 0 5px" @click="deriveBtn">
          <a-icon type="file-excel" style="color: #00A854" />
          导出
        </a-button>
<!--        <a-button>发送邀请</a-button>-->
        <a-button style="margin: 0 5px" type="primary"  @click="addCrew">+ 添加人员</a-button>
      </div>
    </div>
    <div>
      <div style="padding: 20px">

        <div style="display: flex;justify-content: space-between;">
          <!--        邀请-->
          <div style="width: 31%">
            邀请：
            <a-radio-group v-model="changeData.status">
              <a-radio-button value="0" @click="selectBtn('0','status')">
                待邀请<br/>({{topNumberObj.invitation0}})
              </a-radio-button>
              <a-radio-button value="1" @click="selectBtn('1','status')">
                待回复<br/>({{topNumberObj.invitation1}})
              </a-radio-button>
              <a-radio-button value="2" @click="selectBtn('2','status')">
                已拒绝<br/>({{topNumberObj.invitation2}})
              </a-radio-button>
              <a-radio-button value="3" @click="selectBtn('3','status')">
                已接收<br/>({{topNumberObj.invitation3}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--        个人信息-->
          <div style="width: 18%">
            个人信息：
            <a-radio-group v-model="changeData.expertStatus">
              <a-radio-button value="0" @click="selectBtn('0','expertStatus')">
                待确认<br/>({{topNumberObj.expertStatus0}})
              </a-radio-button>
              <a-radio-button value="1" @click="selectBtn('1','expertStatus')">
                已确认<br/>({{topNumberObj.expertStatus1}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--          住宿信息-->
          <div style="width: 29%;">
            住宿：
            <a-radio-group v-model="changeData.hotelStatus">
              <a-radio-button value="0" @click="selectBtn('0','hotelStatus')">
                待确认<br/>({{topNumberObj.hotelStatus0}})
              </a-radio-button>
              <a-radio-button value="1" @click="selectBtn('1','hotelStatus')">
                待安排<br/>({{topNumberObj.hotelStatus1}})
              </a-radio-button>
              <a-radio-button value="2" @click="selectBtn('2','hotelStatus')">
                已安排<br/>({{topNumberObj.hotelStatus2}})
              </a-radio-button>
              <a-radio-button value="3" @click="selectBtn('3','hotelStatus')">
                不安排<br/>({{topNumberObj.hotelStatus3}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--        接站信息-->
          <div style="width: 22%">
            接站：
            <a-radio-group v-model="changeData.jieStatus">
              <a-radio-button value="0" @click="selectBtn('0','jieStatus')">
                待安排<br/>({{topNumberObj.jieStatus0}})
              </a-radio-button>
              <a-radio-button  value="1" @click="selectBtn('1','jieStatus')">
                已安排<br/>({{topNumberObj.jieStatus1}})
              </a-radio-button>
              <a-radio-button  value="2" @click="selectBtn('2','jieStatus')">
                不安排<br/>({{topNumberObj.jieStatus2}})
              </a-radio-button>
            </a-radio-group>
          </div>

        </div>

        <div style="display: flex;justify-content: space-between;margin-top: 20px">
          <!--        去程信息-->
          <div style="width: 31%">
            去程：
            <a-radio-group v-model="changeData.departureStatus">
              <a-radio-button  value="0" @click="selectBtn('0','departureStatus')">
                待确认<br/>({{topNumberObj.departureStatus0}})
              </a-radio-button>
              <a-radio-button  value="1" @click="selectBtn('1','departureStatus')">
                待出票<br/>({{topNumberObj.departureStatus1}})
              </a-radio-button>
              <a-radio-button value="2" @click="selectBtn('2','departureStatus')">
                已出票<br/>({{topNumberObj.departureStatus2}})
              </a-radio-button>
              <a-radio-button  value="3" @click="selectBtn('3','departureStatus')">
                不买票<br/>({{topNumberObj.departureStatus3}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--          讲课内容-->
          <div style="width: 18%">
            讲课内容：
            <a-radio-group v-model="changeData.lectureStatus">
              <a-radio-button value="0" @click="selectBtn('0','lectureStatus')">
                待确认<br/>({{topNumberObj.lectureStatus0}})
              </a-radio-button>
              <a-radio-button  value="1" @click="selectBtn('1','lectureStatus')">
                已确认<br/>({{topNumberObj.lectureStatus1}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--        返程信息-->
          <div style="width: 29%">
            返程：
            <a-radio-group v-model="changeData.fcDepartureStatus">
              <a-radio-button value="0" @click="selectBtn('0','fcDepartureStatus')">
                待确认<br/>({{topNumberObj.fcDepartureStatus0}})
              </a-radio-button>
              <a-radio-button value="1" @click="selectBtn('1','fcDepartureStatus')">
                待出票<br/>({{topNumberObj.fcDepartureStatus1}})
              </a-radio-button>
              <a-radio-button  value="2" @click="selectBtn('2','fcDepartureStatus')">
                已出票<br/>({{topNumberObj.fcDepartureStatus2}})
              </a-radio-button>
              <a-radio-button  value="3" @click="selectBtn('3','fcDepartureStatus')">
                不买票<br/>({{topNumberObj.fcDepartureStatus3}})
              </a-radio-button>
            </a-radio-group>
          </div>
          <!--          送站信息-->
          <div style="width: 22%">
            送站：
            <a-radio-group v-model="changeData.songStatus">
              <a-radio-button  value="0" @click="selectBtn('0','songStatus')">
                待安排<br/>({{topNumberObj.songStatus0}})
              </a-radio-button>
              <a-radio-button  value="1" @click="selectBtn('1','songStatus')">
                已安排<br/>({{topNumberObj.songStatus1}})
              </a-radio-button>
              <a-radio-button value="2" @click="selectBtn('2','songStatus')">
                不安排<br/>({{topNumberObj.songStatus2}})
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
      </div>

      <a-table
          style="padding: 10px"
          bordered
          :row-key="record => record.id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="tableColumns"
          :data-source="tableData"
          :scroll="{ x: '1900px'}"
          :pagination="false"
      >
        <span slot="action" slot-scope="text, record">
          <span>
            <a @click="childenOpen(record)" style="margin-right: 10px">查看/修改</a>
           <a-popconfirm
               v-if="selectedRowKeys.length === 0"
               title="是否确认删除?"
               ok-text="是"
               cancel-text="否"
               @confirm="confirmCancel(record)"
           ><a>删除</a>
        </a-popconfirm>
          </span>
        </span>
        <template slot="status" slot-scope="text, record">
<!--           "status": 0, //状态0待邀请 1待回复 2已拒绝 3已接收 -->
          <span @click="openState(record)">
            <a-tag color="blue" v-if="text === 0">待邀请</a-tag>
            <a-tag color="orange" v-if="text === 1">待回复</a-tag>
            <a-tag color="red" v-if="text === 2">已拒绝</a-tag>
            <a-tag color="green" v-if="text === 3">已接收</a-tag>
          </span>
        </template>
        <template slot-scope="text, record" slot="expertHeadimg">
          <img :src="record.expertHeadimg" style="width: 40px;height: 40px">
        </template>
        <template slot-scope="text, record" slot="fzrTmName">
          <a v-if="record.fzrTmName" @click="openPrincipal(record)">{{record.fzrTmName}}</a>
          <a v-else @click="openPrincipal(record)">设置</a>
        </template>

        <template slot-scope="text, record" slot="expertName">
          <a @click="editPeople(record)"><span :style="{color:record.expertStatus ? 'green':'orange'}">[{{record.expertStatus ? '已确认':'待确认'}}]</span>{{text}}</a>
        </template>

<!--        <template slot-scope="text, record" slot="expertHospital">-->
<!--          <a @click="editPeople(record)">{{text}}</a>-->
<!--        </template>-->

        <template slot-scope="text, record" slot="lectureStatus">
          <a @click="openModel('jk',record)" :style="{color:text ? 'green':'orange'}">{{text ? '已确认':'待确认'}}</a>
        </template>

        <template slot-scope="text, record" slot="modality">
          <span>{{record.modality ? record.modality : '-'}}</span>
        </template>

        <template slot-scope="text, record" slot="departureStatus">
          <span v-if="text === 0 || text === 1 || text === 2|| text === 3"  @click="openModel('qc',record)">
            <a style="color: orange" v-if="text === 0">待确认</a>
            <a style="color: blue" v-if="text === 1">待出票</a>
            <a style="color: green" v-if="text === 2">已出票</a>
            <a style="color: #b8b8b8" v-if="text === 3">不买票</a>
          </span>
          <span v-else>-</span>
        </template>

        <template slot-scope="text, record" slot="fcDepartureStatus" >
          <span v-if="text === 0 || text === 1 || text === 2 || text === 3"  @click="openModel('fc',record)">
            <a style="color: orange" v-if="text === 0">待确认</a>
            <a style="color: blue" v-if="text === 1">待出票</a>
            <a style="color: green" v-if="text === 2">已出票</a>
            <a style="color: #b8b8b8" v-if="text === 3">不买票</a>
          </span>
          <span v-else>-</span>
        </template>

        <template slot-scope="text, record" slot="jieStatus" >
          <span v-if="text === 0 || text === 1|| text === 2"  @click="openModel('jc',record)">
            <a style="color: orange" v-if="text === 0">待安排</a>
            <a style="color: green" v-if="text === 1">已安排</a>
            <a style="color: #b8b8b8" v-if="text === 2">不安排</a>
          </span>
          <span v-else>-</span>
        </template>

        <template slot-scope="text, record" slot="songStatus"  >
          <span v-if="text === 0 || text === 1|| text === 2" @click="openModel('sc',record)">
            <a style="color: orange" v-if="text === 0">待安排</a>
            <a style="color: green" v-if="text === 1">已安排</a>
            <a style="color: #b8b8b8" v-if="text === 2">不安排</a>
          </span>
          <span v-else>-</span>
        </template>

        <template slot-scope="text, record" slot="hotelStatus"  >
          <span v-if="text === 0 || text === 1 || text === 2|| text === 3" @click="openModel('zs',record)">
            <a style="color: orange" v-if="text === 0">待确认</a>
            <a style="color: blue" v-if="text === 1">待安排</a>
            <a style="color: green" v-if="text === 2">已安排</a>
            <a style="color: #b8b8b8" v-if="text === 3">不安排</a>
          </span>
          <span v-else>-</span>
        </template>

        <template slot-scope="text, record" slot="remark">
          <span>{{text ? text:'-'}}</span>
        </template>
      </a-table>

      <!-- 分页 -->
      <div
          style="
            display: block;
            width: 100%;
            height: 80px;
            text-align: center;
            background-color: white;
            padding-top: 10px;
          "
      >
        <a-pagination
            :current="current"
            :total="total"
            :pageSize="pageSize"
            show-quick-jumper
            @change="PageChange"
            style="display: inline-block"
        />
      </div>
    </div>
    </a-spin>

    <a-modal
        class="modalWindow"
        :width="1200"
        title="查看/修改"
        :visible="childenVisible"
        :confirm-loading="confirmLoading"
        @ok="childenOk"
        @cancel="childenCancel"
        :footer="false"
    >
      <div style="margin: -24px;">
        <a-layout>
          <a-layout>
            <a-layout-sider style="background-color: #ffffff">
              <a-menu v-model="selectId"
                      style="width: 100%;border: none;"
                      @select="menuSelect"
                      mode="inline">
                <a-menu-item key="jk">
                <span style="color: orange">
                   <span v-if="selectRow.lectureStatus === 0 || selectRow.lectureStatus === 1">
                     <a style="color: orange" v-if="selectRow.lectureStatus === 0">[待确认]</a>
                     <a style="color: green" v-if="selectRow.lectureStatus === 1">[已确认]</a>
                   </span>
                </span> 讲课信息
                </a-menu-item>
                <a-menu-item key="qc">
                <span style="color: orange">
                  <span v-if="selectRow.departureStatus === 0 || selectRow.departureStatus === 1 || selectRow.departureStatus === 2|| selectRow.departureStatus === 3">
                     <a style="color: orange" v-if="selectRow.departureStatus === 0">[待确认]</a>
                     <a style="color: blue" v-if="selectRow.departureStatus === 1">[待出票]</a>
                     <a style="color: green" v-if="selectRow.departureStatus === 2">[已确认]</a>
                     <a style="color: #b8b8b8" v-if="selectRow.departureStatus === 3">[不买票]</a>
                   </span>
                </span> 去程信息
                </a-menu-item>
                <a-menu-item key="fc">
                <span style="color: orange">
                  <span v-if="selectRow.fcDepartureStatus === 0 || selectRow.fcDepartureStatus === 1 || selectRow.fcDepartureStatus === 2 || selectRow.fcDepartureStatus === 3">
                     <a style="color: orange" v-if="selectRow.fcDepartureStatus === 0">[待确认]</a>
                     <a style="color: blue" v-if="selectRow.fcDepartureStatus === 1">[待出票]</a>
                     <a style="color: green" v-if="selectRow.fcDepartureStatus === 2">[已确认]</a>
                     <a style="color: #b8b8b8" v-if="selectRow.fcDepartureStatus === 3">[不买票]</a>
                   </span>
                </span> 返程信息
                </a-menu-item>
                <a-menu-item key="jc">
                <span style="color: orange">
                   <span v-if="selectRow.jieStatus === 0 || selectRow.jieStatus === 1 || selectRow.jieStatus === 2">
                     <a style="color: orange" v-if="selectRow.jieStatus === 0">[待安排]</a>
                     <a style="color: green" v-if="selectRow.jieStatus === 1">[已安排]</a>
                     <a style="color: #b8b8b8" v-if="selectRow.jieStatus === 2">[不安排]</a>
                   </span>
                </span> 接程信息
                </a-menu-item>
                <a-menu-item key="sc">
                <span style="color: orange">
                   <span v-if="selectRow.songStatus === 0 || selectRow.songStatus === 1|| selectRow.songStatus === 2">
                     <a style="color: orange" v-if="selectRow.songStatus === 0">[待安排]</a>
                     <a style="color: green" v-if="selectRow.songStatus === 1">[已安排]</a>
                     <a style="color: #b8b8b8" v-if="selectRow.songStatus === 2">[不安排]</a>
                   </span>
                </span> 送程信息
                </a-menu-item>
                <a-menu-item key="zs">
                <span style="color: orange">
                  <span v-if="selectRow.hotelStatus === 0 || selectRow.hotelStatus === 1 || selectRow.hotelStatus === 2 || selectRow.hotelStatus === 3">
                     <a style="color: orange" v-if="selectRow.hotelStatus === 0">[待确认]</a>
                     <a style="color: blue" v-if="selectRow.hotelStatus === 1">[待安排]</a>
                     <a style="color: green" v-if="selectRow.hotelStatus === 2">[已安排]</a>
                     <a style="color: #b8b8b8" v-if="selectRow.hotelStatus === 3">[不安排]</a>
                   </span>
                </span> 住宿信息
                </a-menu-item>
              </a-menu>
            </a-layout-sider>
            <a-layout-content style="background-color: #ffffff;height: 690px;padding-left: 15px;border-left: 1px solid #f5f5f5" >
              <a-tag style="width: 99%;height: 40px;display: flex;align-items: center;padding-left: 20px;margin-top: 20px" color="blue">
                <div style="color: black;font-size: 14px">
                  <span>姓名：{{selectRow.expertName}}</span>
                  <span style="margin-left: 10px">单位：{{selectRow.expertHospital}}</span>
                  <span style="margin-left: 10px">手机号：{{selectRow.expertTel}}</span>
                  <span v-if="selectRow.expertIdNumber" style="margin-left: 10px">身份证：{{selectRow.expertIdNumber}}</span>
                </div>
              </a-tag>
              <!--            讲课-->
              <div style="padding: 10px" v-if="selectId[0] === 'jk'">
                <div style="display: flex;    align-items: center; justify-content: space-between;margin-top: 10px">
                  <a-button type="primary" @click="jkOpenAdd">+ 新 增</a-button>
                  <div>
                    确认状态：
                    <a-select v-model="lectureStatus" placeholder="请选择" style="width: 120px">
                      <a-select-option value="0">
                        待确认
                      </a-select-option>
                      <a-select-option value="1">
                        已确认
                      </a-select-option>
                    </a-select>
                    <a-button style="margin-left: 10px" type="primary" @click="editLectureStatus">保存</a-button>
                  </div>
                </div>
<!--                <div style="margin-top: 20px">-->
<!--                  <span>讲者任务数量：<span style="color: #1890FF">{{selectRow.speakerNumber ? selectRow.speakerNumber : 0}}</span></span>-->
<!--                  <span style="margin-left: 20px">已上传数量：<span style="color: red">{{selectRow.speakerUpNumber ? selectRow.speakerUpNumber : 0}}</span></span>-->
<!--                </div>-->
                <a-table
                    style="margin-top: 20px"
                    :columns="LectureColumns"
                    :data-source="LectureData"
                    :pagination="false"
                >
                <span slot="operation" slot-scope="text, record">
                  <a style="margin-right: 5px" @click="jkOpen(record)">修改</a>
                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="confirmLecture(record)"
                  ><a>删除</a>
                  </a-popconfirm>
                </span>
                  <span slot="courseware" slot-scope="text,record">
                  <span v-if="record.courseware">
                    <admin_con @click="openUrl(record.courseware)" style="font-size: 33px;margin-right: 10px" type="icon-kejian"/>
                  </span>
                  <span v-if="record.video">
                    <admin_con @click="openUrl(record.video)"  style="font-size: 34px" type="icon-shipin1"/>
                  </span>
                  <span v-if="!record.video && !record.courseware">-</span>
                </span>
                </a-table>

                <!-- 分页 -->
                <div
                    style="
            display: block;
            width: 100%;
            height: 50px;
            text-align: center;
            background-color: white;
            padding-top: 10px;
          "
                >
                  <a-pagination
                      :current="currentLecture"
                      :total="Lecturetotal"
                      :pageSize="pageSizeLecture"
                      show-quick-jumper
                      @change="PageNOchangeLecture"
                      style="display: inline-block"
                  />
                </div>
              </div>
              <!--            去程/返程-->
              <div v-else-if="selectId[0] === 'qc' || selectId[0] === 'fc'">


                <a-form-model
                    ref="qcRef"
                    style="margin: 0 auto"
                    :rules="qcRules"
                    :model="qcForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
                  <a-form-model-item label="是否订票">
                    <a-radio-group v-model="qcForm.departrueIsBooking">
                      <a-radio :value="1">是</a-radio>
                      <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <template v-if="qcForm.departrueIsBooking">
                    <a-form-model-item label="出发日期" prop="departureDate">
                      <a-date-picker style="width: 100%"
                                     v-model="qcForm.departureDate"
                                     placeholder="请选择日期"/>
                    </a-form-model-item>
                    <a-form-model-item label="出发时间段">
<!--                      departureStarTime   departureEndTime   -->
                      <a-row>
                        <a-col :span="6">
                          <a-time-picker
                              style="width: 140px"
                              placeholder="请选择开始时间"
                              v-model="startTime"
                              :disabledHours="disabledStartHours"
                              :disabledMinutes="disabledStartMinutes"
                              @change="selectStartTime"
                              @openChange="blurStartTime"
                              hideDisabledOptions
                              format="HH:mm" />
                        </a-col>
                        <a-col :span="1"><span style="text-align: center">-</span></a-col>

                        <a-col :span="6" style="margin-left: -13px">
                          <a-time-picker
                              style="width: 140px"
                              placeholder="请选择结束时间"
                              v-model="endTime"
                              :disabledHours="disabledEndHours"
                              :disabledMinutes="disabledEndMinutes"
                              @change="selectEndTime"
                              hideDisabledOptions
                              format="HH:mm" />
                        </a-col>
                      </a-row>
                    </a-form-model-item>
                    <a-form-model-item label="交通工具" prop="departureVehicle">
                      <a-select placeholder="请选择"
                                v-model="qcForm.departureVehicle">
                        <a-select-option value="火车">
                          火车
                        </a-select-option>
                        <a-select-option value="高铁">
                          高铁
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="出发站" prop="departureStation">
                      <a-input placeholder="请输入" v-model="qcForm.departureStation"/>
                    </a-form-model-item>
                    <a-form-model-item label="到达站" prop="destination">
                      <a-input placeholder="请输入" v-model="qcForm.destination"/>
                    </a-form-model-item>
                    <a-form-model-item label="航班/车次">
                      <a-input placeholder="请输入" v-model="qcForm.flightTrainNumber"/>
                    </a-form-model-item>
                    <a-form-model-item label="状态" prop="departureStatus">
                      <a-select placeholder="请选择" v-model="qcForm.departureStatus">
                        <a-select-option :value="0">待确认</a-select-option>
                        <a-select-option :value="1">待出票</a-select-option>
                        <a-select-option :value="2">已出票</a-select-option>
                        <a-select-option :value="3">不买票</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                  <a-form-model-item label="备注">
                    <a-textarea placeholder="请输入" v-model="qcForm.departureRemark"/>
                  </a-form-model-item>
                </a-form-model>
                <div style="text-align: center">
                  <a-button type="primary"
                            @click="qcSave"
                            style="width: 100px">保 存</a-button>
                </div>
              </div>
              <!--            接乘/送乘-->
              <div style="padding: 10px" v-if="selectId[0] === 'jc' || selectId[0] === 'sc'">
                <a-form-model ref="jcRef" style="margin: 0 auto"
                              :rules="jcRules" :model="jcForm"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol">
                  <a-form-model-item label="是否接" prop="isJie">
                    <a-radio-group v-model="jcForm.isJie">
                      <a-radio :value="1">是</a-radio>
                      <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <template v-if="jcForm.isJie">
                    <a-form-model-item label="参会人员行程时间">
                      <a-input v-model="selectLinkmanList.time" disabled/>
                    </a-form-model-item>
                    <a-form-model-item label="联系人" prop="jieTmId">
                      <a-select placeholder="请选择"
                                v-model="jcForm.jieTmId">
                        <a-select-option :value="item.id"
                                         v-for="(item,index) in linkmanList"
                                         @click="selectLinkman(item)"
                                         :key="index">
                          {{item.name}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="联系电话">
                      <a-input v-model="selectLinkmanList.jieTmPhone" placeholder="请输入" disabled/>
                    </a-form-model-item>
                    <a-form-model-item label="车牌号">
                      <a-input v-model="selectLinkmanList.jieTmCarNumber" placeholder="请输入" disabled/>
                    </a-form-model-item>
                    <a-form-model-item label="车辆信息">
                      <a-input v-model="selectLinkmanList.jieTmCarInfo" placeholder="请输入" disabled/>
                    </a-form-model-item>
                    <a-form-model-item label="状态" prop="jieStatus">
                      <a-select placeholder="请选择" v-model="jcForm.jieStatus">
                        <a-select-option :value="0">待安排</a-select-option>
                        <a-select-option :value="1">已安排</a-select-option>
                        <a-select-option :value="2">不安排</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                  <div style="text-align: center">
                    <a-button type="primary" @click="jcSave('jc')" style="width: 100px">保 存</a-button>
                  </div>
                </a-form-model>
              </div>
              <!--            住宿信息-->
              <div style="padding: 10px" v-if="selectId[0] === 'zs'">
                <a-form-model ref="zsRef" style="margin: 0 auto"
                              :rules="zsRules"
                              :model="zsForm"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol">
                  <a-form-model-item label="是否住宿" prop="isHotel">
                    <a-radio-group v-model="zsForm.isHotel">
                      <a-radio :value="1">是</a-radio>
                      <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <template v-if="zsForm.isHotel">
                    <a-form-model-item label="入住日期" prop="hotelCheckInDate">
                      <a-date-picker style="width: 100%"
                                     :disabled-date="disabledStartDate"
                                     v-model="zsForm.hotelCheckInDate"
                                     placeholder="请选择日期"/>
                    </a-form-model-item>
                    <a-form-model-item label="离店日期" prop="hotelDepartureDate">
                      <a-date-picker style="width: 100%"
                                     v-model="zsForm.hotelDepartureDate"
                                     :disabled-date="disabledEndDate"
                                     placeholder="请选择日期"/>
                    </a-form-model-item>
                    <a-form-model-item label="住宿酒店" prop="hotelId">
                      <a-select placeholder="请选择"
                                v-model="zsForm.hotelId">
                        <a-select-option :value="item.id"
                                         v-for="(item,index) in hotelList"
                                         :key="index">
                          {{item.name}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="房型要求">
                      <a-input v-model="zsForm.roomType" placeholder="请输入"/>
                    </a-form-model-item>
                    <a-form-model-item label="同住人">
                      <a-input v-model="zsForm.hotelCohabitant" placeholder="请输入"/>
                    </a-form-model-item>
                    <a-form-model-item label="房间号">
                      <a-input v-model="zsForm.hotelRoomNumber" placeholder="请输入"/>
                    </a-form-model-item>
                    <a-form-model-item label="状态">
                      <a-select placeholder="请选择"
                                v-model="zsForm.hotelStatus">
                        <a-select-option :value="0">待确认</a-select-option>
                        <a-select-option :value="1">待安排</a-select-option>
                        <a-select-option :value="2">已安排</a-select-option>
                        <a-select-option :value="3">不安排</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                  <div style="text-align: center">
                    <a-button type="primary" @click="zsSave" style="width: 100px">保 存</a-button>
                  </div>
                </a-form-model>
              </div>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </div>
    </a-modal>

    <a-modal
        width="700px"
        :title="titleCrew"
        :visible="visiblelectureCrew"
        :footer="false"
        @cancel="addCrewlectureCancel"
    >
      <a-form-model
          ref="LectureCrewRef"
          :model="CrewForm"
          :rules="rulesLectureCrew"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 12 }">
        <a-form-model-item label="课件题目" prop="subject">
          <a-input style="width: 350px" v-model="CrewForm.subject" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="讲课类型" prop="modality">
          <a-select v-model="CrewForm.modality"
                    placeholder="请选择"
                    style="width: 350px">
            <a-select-option value="现场演讲">
              现场演讲
            </a-select-option>
            <a-select-option value="线上演讲">
              线上演讲
            </a-select-option>
            <a-select-option value="视频录播">
              视频录播
            </a-select-option>
          </a-select>
          <!--          <a-input style="width: 350px" v-model="CrewForm.modality" placeholder="请输入"/>-->
        </a-form-model-item>
        <a-form-model-item label="课件上传">
          <div class="uploadpdf">
            <input
                multiple
                id="pdf"
                ref="pdfRef"
                style="width: 100%; height: 100%; opacity: 0"
                accept=".pdf, .pptx"
                type="file"
                @change="content_pdf('pdf')"
            />
          </div>
          <div style="margin-bottom: 5px;width: 500px;">
            {{ CrewForm.name }} <span v-if="CrewForm.name">: </span>
            <div v-if="CrewForm.courseware">
              <div style="display: flex;">
                <a-progress :percent="CrewForm.progress" style="width: 380px;"/>
                <a-icon type="close-circle" @click="delectPdfList" style="color: red;margin-top: 5px;cursor: pointer"/>
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="视频上传">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  :disabled="disabledVideo"
                  id="up_video_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file" accept=".mp4"
                  @change="content_surface_plot_original_video($event)" />
            </div>
            <div
                @click="openVideoUrl(CrewForm.video)"
                class="upload_LiveBroadcast">
              <video
                  style="width: 100%;height:100%"
                  :src="CrewForm.video"></video>
            </div>
          </div>
          <div style="color: #a1a1a1">注：视频格式为mp4格式</div>
          <div v-if="disabledVideo" style="color: #ff0000">视频上传中...</div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="addCrewlectureCancel">
          取消
        </a-button>
        <a-button type="primary" @click="addCrewSubmitLecture">
          确认
        </a-button>
      </div>
    </a-modal>

    <a-modal
        title="添加人员"
        :visible="visibleCrew"
        @ok="CrewOk"
        @cancel="CrewCancel"
        :footer="null"
    >
      <div>
        <span>参会人员：</span>
        <a-select
            show-search
            option-filter-prop="children"
            @popupScroll="popupScroll_expert"
            @search="select_expert"
            style="width: 300px;margin-bottom: 15px"
            class="input"
            v-model="expertId"
            placeholder="请选择"
        >
          <span slot="notFoundContent">
             <div style="text-align: center">
               <admin_con style="font-size: 40px" type="icon-kong-hezi"/>
                 <br/>
               <span style="font-size: 12px">暂无数据,请去参会人员管理中维护</span>
             </div>
          </span>
          <a-select-option
              v-for="item in expertList"
              :value="item.id"
          >
            {{ item.name }} - {{item.hospital}}
          </a-select-option>
        </a-select>
      </div>
      <div style="margin-bottom:30px">
        <span style="padding-left:14px">负责人：</span>
        <a-select
            allowClear
            show-search
            v-model="fzrTmId"
            placeholder="请选择"
            style="width: 300px;margin-bottom: 15px"
            optionFilterProp="label"
        >
          <span slot="notFoundContent">
             <div style="text-align: center">
               <admin_con style="font-size: 40px" type="icon-kong-hezi"/>
                 <br/>
               <span style="font-size: 12px">暂无数据,请去执行人员管理中维护</span>
             </div>
          </span>
          <a-select-option v-for="item in principal2Data" :key="item.id" :value="item.id" :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div
        :style="{
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 0px 0px 0px',
            background: '#fff',
            textAlign: 'right',

          }"
      >
        <a-button @click="CrewCancel"> 取消 </a-button>

        <a-button style="margin-left: 15px" type="primary" @click="CrewOk('true')">
         继续提交
        </a-button>
        <a-button style="margin-left: 15px;border: 1px solid #45A5E6;color: #45A5E6" @click="CrewOk('false')">
          提交
        </a-button>
      </div>
    </a-modal>

    <a-modal
        title="邀请状态"
        :visible="visibleState"
        @ok="StateOk"
        @cancel="StateCancel"
    >
      邀请状态：
      <a-select
          v-model="InvitationStatus"
          placeholder="请选择"
          style="width: 200px"
      >
        <!--             状态0待邀请 1待回复 2已拒绝 3已接收 -->
        <a-select-option :value="0">待邀请</a-select-option>
        <a-select-option :value="1">待回复</a-select-option>
        <a-select-option :value="2">已拒绝</a-select-option>
        <a-select-option :value="3">已接收</a-select-option>
      </a-select>
    </a-modal>

    <a-modal
        title="负责人"
        :visible="visiblePrincipal"
        @ok="PrincipalOk"
        @cancel="PrincipalCancel"
    >
<!--      allowClear-->
      负责人：
      <a-select
          show-search
          v-model="PrincipalId"
          placeholder="请选择"
          style="width: 200px"
      >
        <a-select-option v-for="item in principal2Data"
                         :key="item.id"
                         :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>

    <!-- 新增/修改 -->
    <ExpertViewone
        v-if="addShow"
        :visible="addShow"
        :addTitle="addTitle"
        :formPage="formPage"
        :expertId="expertIdTable"
        :addForm="addForm"
        v-on:closeMain="closeMain"
    />
  </div>
</template>

<script>
import ExpertViewone from "@/components/meet/ExpertViewone";
import moment from 'moment'
import TemplateAddress from "@/views/content/medicalConference/templateAddress";
import {update_File, update_Video} from "@/utils/update";
import {admin_icon} from "@/utils/myIcon";
import {
  confereeAddInfo, confereeDelInfo, confereeDelInfoBatches,
  confereeSelectAllNew,
  confereeSelectAllNewOne,
  confereeTeachAddInfo,
  confereeTeachDelInfo,
  confereeTeachSelectAll,
  confereeTeachUpInfo, exportMember,
  getAddInfo,
  selectConventionMemberStatusNumber, selectHotel,
  updInfo
} from "@/service/participants";
import {getMemberList,getNewMemberList} from "@/service/participants";
export default {
  name: "ParticipantsNew",
  props:{
    conventionCode:{
      type:String,
    }
  },
  components: {TemplateAddress, admin_con: admin_icon,ExpertViewone},
  data () {
    return {
      principal2Data:[],
      PrincipalId:undefined,
      visiblePrincipal:false,
      addForm:{},
      formPage:'Participants',
      addTitle:'编辑',
      addShow:false,
      visibleCrew:false,
      expertId:undefined,
      fzrTmId:undefined,
      expertList:[],
      expert_select_page:1,
      selectExpertTitle:'',
      current:1,
      total:0,
      pageSize:10,

      name:'',
      tmId:null,
      status:'',

      qcRules: {
        departureDate: [{required: true, message: '请选择', trigger: 'change'}],
        departureVehicle: [{required: true, message: '请选择', trigger: 'change'}],
        departureStation: [{required: true, message: '请选择', trigger: 'change'}],
        destination: [{required: true, message: '请选择', trigger: 'change'}],
        departureStatus: [{required: true, message: '请选择', trigger: 'change'}],
      },
      selectId: ['jk'],
      disabledVideo:false,
      LectureColumns:[
        {
          title: '讲题',
          dataIndex: 'subject',
          align:'center',
          width: '20%',
          ellipsis: true,
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '形式',
          dataIndex: 'modality',
          align:'center',
          // width: '15%',
          scopedSlots: { customRender: 'modality' },
        },
        {
          title: '课件/视频',
          dataIndex: 'courseware',
          // align:'center',
          // width: '15%',
          scopedSlots: { customRender: 'courseware' },
        },
        {
          title: '操作',
          align:'center',
          // width: '15%',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      visiblelectureCrew: false,
      LectureData: [],
      childenVisible: false,
      confirmLoading: false,
      spinning: false,
      tableColumns: [
        {
          title: '邀请状态',
          dataIndex: 'status',
          align:'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '负责人',
          dataIndex: 'fzrTmName',
          align:'center',
          scopedSlots: { customRender: 'fzrTmName' },
        },
        {
          title: '个人信息',
          children: [
            {
              title: '头像',
              dataIndex: 'expertHeadimg',
              align:'center',
              scopedSlots: { customRender: 'expertHeadimg' },
            },
            {
              title: '姓名', // "expertStatus": 0, //专家信息在本次会议确认状态 0待确认 1已确认
              dataIndex: 'expertName',
              // align:'center',
              scopedSlots: { customRender: 'expertName' },
            },
            {
              title: '单位',
              dataIndex: 'expertHospital',
              align:'center',
              scopedSlots: { customRender: 'expertHospital' },
            }
          ]
        },
        {
          title: '讲课内容',
          align:'center',
          children: [
            {
              title: '状态', //"lectureStatus": 0, //讲课内容状态 0待确认 1已确认
              dataIndex: 'lectureStatus',
              align:'center',
              scopedSlots: { customRender: 'lectureStatus' },
            },
            // {
            //   title: '形式', //"modality": null, //讲课形式
            //   dataIndex: 'modality',
            //   align:'center',
            //   scopedSlots: { customRender: 'modality' },
            // }
          ]
        },
        {
          title: '意向行程',
          children: [
            {
              title: '去程', // "departureStatus": null, //去程信息确认状态 0待确认 1待出票 2已出票
              dataIndex: 'departureStatus',
              align:'center',
              scopedSlots: { customRender: 'departureStatus' },
            },
            {
              title: '返程', //"fcDepartureStatus": null, //反程信息确认状态 0待确认 1待出票 2已出票
              dataIndex: 'fcDepartureStatus',
              align:'center',
              scopedSlots: { customRender: 'fcDepartureStatus' },
            }
          ]
        },
        {
          title: '接送站',
          children: [
            {
              title: '接程', //"jieStatus": 0, //接状态0待安排1已安排
              dataIndex: 'jieStatus',
              align:'center',
              scopedSlots: { customRender: 'jieStatus' },
            },
            {
              title: '送程', //"songStatus": 0, //送状态0待安排1已安排
              dataIndex: 'songStatus',
              align:'center',
              scopedSlots: { customRender: 'songStatus' },
            }
          ]
        },
        {
          title: '住宿安排',
          align:'center',
          children: [
            {
              title: '状态', //"hotelStatus": 0, //住宿信息确认状态 0待确认 1待安排 2已安排
              dataIndex: 'hotelStatus',
              align:'center',
              scopedSlots: { customRender: 'hotelStatus' },
            }
          ]
        },
        {
          title: '备注', // "remark": null //备注
          dataIndex: 'remark',
          align:'center',
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          align:'center',
          scopedSlots: { customRender: 'action' },
        }
      ],
      tableData: [],
      topNumberObj: {},
      timeout: null,
      CrewForm: {},
      jcForm: {
        "isJie": 1, //是否接 1是0否
        "jieTmId": null, //接内部人id
        "jieStatus": 0, //接状态0待安排1已安排
      },
      selectLinkmanList:{},
      jcRules: {
        isJie: [{required: true, message: '请选择', trigger: 'change'}],
        jieTmId: [{required: true, message: '请选择', trigger: 'change'}],
        jieStatus: [{required: true, message: '请选择', trigger: 'change'}],
      },
      rulesLectureCrew:{
        subject: [{required: true, message: '请输入', trigger: 'blur'}],
        modality: [{required: true, message: '请选择', trigger: 'change'}],
      },
      changeData: {
        status:undefined,
        expertStatus: undefined, // 个人信息-专家信息在本次会议确认状态 0待确认 1已确认
        lectureStatus: undefined, // 讲课内容 - 讲课内容状态 0待确认 1已确认
        hotelStatus: undefined, // 住宿信息 - 住宿信息确认状态 0待确认 1待安排 2已安排
        departureStatus: undefined, //去程信息 - 去程信息确认状态 0待确认 1待出票 2已出票
        jieStatus: undefined, //接站信息 - 接状态0待安排1已安排
        fcDepartureStatus: undefined, //返程信息 - 返程信息确认状态 0待确认 1待出票 2已出票
        songStatus: undefined, //送站信息 - 送状态0待安排1已安排
      },
      lectureStatus:undefined,
      zsRules: {
        isHotel: [{required: true, message: '请选择', trigger: 'change'}],
        hotelCheckInDate: [{required: true, message: '请选择', trigger: 'change'}],
        hotelDepartureDate: [{required: true, message: '请选择', trigger: 'change'}],
        hotelId: [{required: true, message: '请选择', trigger: 'change'}],
      },
      zsForm: {
        "isHotel": 1, //是否住宿1是0否
        "hotelCheckInDate": null, //入住日期
        "hotelDepartureDate": null, //离店日期
        "hotelId": null, //住宿酒店id
        "roomType": null, //房型要求
        "hotelCohabitant": null, //同住人
        "hotelRoomNumber": null, //房间号
        "hotelStatus": 0, //住宿信息确认状态 0待确认 1待安排 2已安排
      },
      principalData: [
        // {
        //   id: 'f94db610-dfc5-46fd-942c-3051c8539c85',
        //   text: '韩笑'
        // }
      ],
      selectRow: {},
      jkVisible: false,
      qcForm: {
        "departrueIsBooking": 0, //去程是否订票0否1是
        "departureDate": null, //出发日期
        "departureStarTime": null, //出发时间段开始时间
        "departureEndTime": null, //出发时间段结束时间
        "departureVehicle": undefined, //交通工具
        "departureStation": null, //始发站
        "destination": null, //到达站
        "flightTrainNumber": null, //航班、车次
        "departureStatus": undefined, //去程信息确认状态 0待确认 1待出票 2已出票
        "departureRemark": null, //去程备注
      },
      startTime:null,
      endTime:null,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      expertIdTable:'',
      titleCrew:'新建',

      Lecturetotal:0,
      currentLecture:1,
      pageSizeLecture:10,
      hotelList:[],
      linkmanList:[
        // {
        //   "jieTmId": 'f94db610-dfc5-46fd-942c-3051c8539c85', //接内部人id
        //   "jieTmName": '韩笑', //姓名
        //   "jieTmPhone": 13131313131, //手机号
        //   "jieTmCarInfo": '车辆信息', //车辆信息
        //   "jieTmCarNumber": '车牌号', //车牌号
        // }
      ],
      InvitationStatus:1,
      visibleState:false,
      tableId:null,
      selectedRowKeys: [],

    }//dataReturn
  },
  created() {
    this.getConfereeSelect();
    this.getSelectConventionMemberStatusNumber();
    this.getHotelList();
    //内部成员insider，接送成员meetingMember
    this.getMember('insider');
    this.getMember('meetingMember');
  },
  methods: {
    disabledStartDate(current) {
      const endDate = this.zsForm.hotelDepartureDate ? this.zsForm.hotelDepartureDate : null;
      // const today = new Date();
      const today = new Date().setHours(0, 0, 0, 0);
      if(endDate){ //离店日期
        //选择小于结束日期大于等于当前日期
        return current && current > endDate
      }else {
        //可以选择大于等于当前日期
        return current && current < new Date().setHours(0, 0, 0, 0);
      }
    },
    disabledEndDate(current) {
      const startDate = this.zsForm.hotelCheckInDate ? this.zsForm.hotelCheckInDate : null;
      // const today = new Date();
      const today = new Date().setHours(0, 0, 0, 0);
      if(startDate){ //入住日期
        return current && current < startDate
      }
      return current && current > today
      // // 禁用开始日期之后的日期
      // return current && current < this.zsForm.hotelCheckInDate;

    },
    openPrincipal(row){
      this.tableId = row.id;
      this.PrincipalId = row.fzrTmId ? row.fzrTmId : undefined;
      this.getpersonInCharge()
      this.visiblePrincipal = true;
    },
    async PrincipalOk() {
      if(this.PrincipalId){
        let data = {
          id: this.tableId,
          fzrTmId: this.PrincipalId
        }
        await this.putConfereeInfo(data);
        await this.getConfereeSelect();
        this.PrincipalCancel();
      }else {
        this.$message.warning('请先选择负责人！')
      }
    },
    PrincipalCancel(){
      this.PrincipalId = undefined;
      this.visiblePrincipal = false;
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 医院框输入
     async selectChange(value) {
      this.principalData = [];
      let data = {
        name:value,
        pageNo:1,
        pageSize:9999,
      }
      const response = await getNewMemberList(this.conventionCode,data)
      if(response.code === 0){
        this.principalData = response.data; //负责人
      }
    },
    openState(item){
      this.InvitationStatus = item.status;
      this.tableId = item.id;
      this.visibleState = true;
    },
    async StateOk() {
      // console.log(this.InvitationStatus)
      let data = {
        id: this.tableId,
        status: this.InvitationStatus
      }
      this.StateCancel();
      await this.putConfereeInfo(data);
      await this.getConfereeSelect();
    },
    StateCancel(){
      this.visibleState = false;
    },
    closeMain(val) {
      this.addShow = val;
    },
    selectLinkman(item){
      this.selectLinkmanList.jieTmId = item.id;
      this.selectLinkmanList.jieTmName = item.name;
      this.selectLinkmanList.jieTmPhone = item.phone;
      this.selectLinkmanList.jieTmCarInfo = item.carInfo;
      this.selectLinkmanList.jieTmCarNumber = item.carNumber;

      console.log(this.selectLinkmanList)
      // this.selectLinkmanList = item;
    },
    editPeople(row){
      // console.log(row.expertId)
      this.expertIdTable = row.expertId;
      this.addShow = true;
    },
    addCrew(){
      this.getLogData();
      this.expertId = undefined;
      this.visibleCrew = true;
      this.getpersonInCharge()
    },
    // 负责人
    async  getpersonInCharge(){
      let data = {
        name:'',
        pageNo:1,
        pageSize:9999,
      }
      const response = await getMemberList('insider',data)
      if(response.code === 0){
        this.principal2Data = response.data; //负责人
      }
    },
    //获取专家列表
    async getLogData(){
      const data={
        name:this.selectExpertTitle,
        // office:this.office,
        page:this.expert_select_page,
        pageSize:10,
      }
      const info = await  getAddInfo(data)
      if (info.code === 200){

        let list  = info.data;
        for (let i=0;i<list.length;i++){
          this.expertList.push(list[i]);
        }
      }
    },
    //滚动条监听
    popupScroll_expert(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.expert_select_page++;
        this.getLogData();
      }
    },
    select_expert(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.expertList = [];
      this.expert_select_page = 1;
      this.selectExpertTitle = value;
      this.getLogData();
    },
    CrewOk(type){
      if(this.expertId){
        let data = {
          "conventionCode":this.conventionCode,//会议code
          "expertId":this.expertId,//专家id
          "fzrTmId":this.fzrTmId,//专家id
        }
        this.postConfereeAddInfo(data,type);
      }else {
        this.$message.warning("请先选择参会人员！");
      }

    },
    //新增参会人员管理
    async postConfereeAddInfo(data,type) {
      const response = await confereeAddInfo(data)
      if(response.code === 200){
        this.$message.success("新增成功！");
        if(type=="false"){
          this.CrewCancel();
        }
        if(type=='true'){
         this.expertId = undefined;
         this.fzrTmId = undefined;
         this.expert_select_page = 1;
        this.selectExpertTitle = '';
        }
        await this.getConfereeSelect(); //刷新页面
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改参会人员管理
    async putConfereeInfo(data) {
      const response = await updInfo(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
      }else {
        this.$message.warning(response.message)
      }
    },
    editLectureStatus(){
      let data = {
        "id": this.selectRow.id,
        "lectureStatus": parseInt(this.lectureStatus), //讲课内容状态 0待确认 1已确认
      }
      this.putConfereeInfo(data); //修改讲课内容状态
      this.getOneInfo(this.selectRow.id);
    },
    openUrl(url){
      // .pptx文件会多打开一个窗口
      if(url.substring(url.length-5,url.length) === '.pptx'){
        window.location.href=url
      }else{
        window.open(url)
      }
    },
    CrewCancel(){
      this.visibleCrew = false;
      this.expertId = undefined;
      this.fzrTmId = undefined;
      this.expert_select_page = 1;
      this.expertList = [];
      this.selectExpertTitle = '';
      this.getSelectConventionMemberStatusNumber();


    },
    //获取参会人员
    async getConfereeSelect() {
      this.spinning = true;
      let data = {
        status:this.changeData.status ? parseInt(this.changeData.status) : null,
        name:this.name,
        tmId:this.tmId,
        page:this.current,
        pageSize:this.pageSize,

        expertStatus: this.changeData.expertStatus ? parseInt(this.changeData.expertStatus) : null, // 个人信息-专家信息在本次会议确认状态 0待确认 1已确认
        lectureStatus: this.changeData.lectureStatus ? parseInt(this.changeData.lectureStatus) : null, // 讲课内容 - 讲课内容状态 0待确认 1已确认
        hotelStatus: this.changeData.hotelStatus ? parseInt(this.changeData.hotelStatus) : null, // 住宿信息 - 住宿信息确认状态 0待确认 1待安排 2已安排
        departureStatus: this.changeData.departureStatus ? parseInt(this.changeData.departureStatus) : null, //去程信息 - 去程信息确认状态 0待确认 1待出票 2已出票
        fcDepartureStatus: this.changeData.fcDepartureStatus ? parseInt(this.changeData.fcDepartureStatus) : null, //返程信息 - 返程信息确认状态 0待确认 1待出票 2已出票
        jieStatus: this.changeData.jieStatus ? parseInt(this.changeData.jieStatus) : null, //接站信息 - 接状态0待安排1已安排
        songStatus: this.changeData.songStatus ? parseInt(this.changeData.songStatus) : null, //送站信息 - 送状态0待安排1已安排
      }
      const response = await confereeSelectAllNew(this.conventionCode,data)
      if(response.code === 200){
        this.tableData = response.data;
        this.total = response.count;
      }
      this.spinning = false;
    },
    PageChange(current,pageSize){
      this.current = current;
      this.pageSize = pageSize;
      this.getConfereeSelect()
    },
    //获取参会人员
    async getSelectConventionMemberStatusNumber() {
      this.spinning = true;
      const response = await selectConventionMemberStatusNumber(this.conventionCode)
      if(response.code === 200){
        this.topNumberObj = response.data;
      }
      this.spinning = false;
    },
    //获取负责人员
    async getMember(type) {
      let data = {
        name:'',
        pageNo:1,
        pageSize:9999,
      }
      const response = await getNewMemberList(this.conventionCode,data)
      if(response.code === 0){
        //内部成员insider，接送成员meetingMember
        if(type === 'insider'){

          this.principalData = response.data; //负责人

        }else if(type === 'meetingMember'){ //接送人

          this.linkmanList = response.data;

        }

      }
    },
    async deriveBtn(){
      if(this.selectedRowKeys.length){ //批量导出
        let data = {
          idList: this.selectedRowKeys,
          ExelName:'参会人员'+new Date().getTime(),
        }
        const res = await exportMember(this.conventionCode,data)

      }else { //导出全部
        if(this.tableData.length){
          let data = {
            ExelName:'参会人员'+new Date().getTime()
          }
          const res = await exportMember(this.conventionCode,data)
        }else {
          this.$message.warning("当前无数据导出！")
        }
      }
    },
    //获取本次会议住宿酒店信息
    async getHotelList() {
      const response = await selectHotel(this.conventionCode)
      if(response.code === 200){
        this.hotelList = response.data;
      }
    },
    zsSave () {
      this.$refs.zsRef.validate(valid => {
        if (valid) {
          let form = this.zsForm;
          let data = {
            "id": this.selectRow.id,
            "isHotel": form.isHotel, //是否住宿1是0否
            "hotelCheckInDate": form.hotelCheckInDate, //入住日期
            "hotelDepartureDate": form.hotelDepartureDate, //离店日期
            "hotelId": form.hotelId, //住宿酒店id
            "roomType": form.roomType, //房型要求
            "hotelCohabitant": form.hotelCohabitant, //同住人
            "hotelRoomNumber": form.hotelRoomNumber, //房间号
            "hotelStatus": form.hotelStatus, //住宿信息确认状态 0待确认 1待安排 2已安排
          }
          this.load(data);
        } else {
          this.$message.warning('请填写完整表单内容')
          return false;
        }
      })
    },
    //刷新
    async load(data) {
      await this.putConfereeInfo(data);
      await this.getOneInfo(this.selectRow.id);
    },
    jcSave (type) {
      this.$refs.jcRef.validate(valid => {
        if (valid) {
          let form = this.jcForm;
          let data = {};
          if (this.selectId[0] === 'jc') { //接程保存
            data = {
              "id": this.selectRow.id,
              "isJie": form.isJie, //是否接 1是0否
              "jieTmId": form.jieTmId, //接内部人id
              "jieStatus": form.jieStatus, //接状态0待安排1已安排
            }
            this.load(data);

          } else if (this.selectId[0] === 'sc') { //送程保存
            data = {
              "id": this.selectRow.id,
              "isSong": form.isJie, //是否送 1是0否
              "songTmId": form.jieTmId, //送内部人id
              "songStatus": form.jieStatus, //送状态0待安排1已安排
            }
            this.load(data);
          }
        } else {
          this.$message.warning('请填写完整表单内容')
          return false;
        }
      })
    },
    qcSave () {
      this.$refs.qcRef.validate(valid => {
        if (valid) {
          let form = this.qcForm;
          let data = {};
          if (this.selectId[0] === 'qc') { //去程保存
            let departureDate =form.departureDate ? moment(form.departureDate).format('YYYY-MM-DD'):null;
            data = {
              "id": this.selectRow.id,
              "departrueIsBooking": form.departrueIsBooking, //去程是否订票0否1是
              "departureDate":departureDate, //出发日期
              "departureStarTime": form.departureStarTime ? departureDate + ' ' + form.departureStarTime:null, //出发时间段开始时间
              "departureEndTime":  form.departureEndTime ? departureDate + ' ' + form.departureEndTime:null, //出发时间段结束时间
              "departureVehicle": form.departureVehicle, //交通工具
              "departureStation": form.departureStation, //始发站
              "destination": form.destination, //到达站
              "flightTrainNumber": form.flightTrainNumber, //航班、车次
              "departureStatus": form.departureStatus, //去程信息确认状态 0待确认 1待出票 2已出票
              "departureRemark": form.departureRemark, //去程备注
            }
            this.load(data);
          } else if (this.selectId[0] === 'fc'){ // 返程保存
            let fcDepartureDate = form.departureDate ? moment(form.departureDate).format('YYYY-MM-DD'):null;
            data = {
              "id": this.selectRow.id,
              "fcDepartrueIsBooking": form.departrueIsBooking, //返程是否订票0否1是
              "fcDepartureDate": fcDepartureDate, //反程出发日期
              "fcDepartureStarTime": form.departureStarTime ? fcDepartureDate + ' ' + form.departureStarTime:null, //反程出发时间段开始时间
              "fcDepartureEndTime": form.departureEndTime ? fcDepartureDate + ' ' + form.departureEndTime:null, //反程出发时间段结束时间
              "fcDepartureVehicle": form.departureVehicle, //反程交通工具
              "fcDepartureStation": form.departureStation, //反程始发站
              "fcDestination": form.destination, //反程到达站
              "fcFlightTrainNumber": form.flightTrainNumber, ///反程航班、车次
              "fcDepartureStatus": form.departureStatus, //反程信息确认状态 0待确认 1待出票 2已出票
              "fcDepartureRemark": form.departureRemark, //反程备注
            }
            this.load(data);
          }
        } else {
          this.$message.warning('请填写完整表单内容')
          return false;
        }
      })
    },
    menuSelect (e) {
      this.selectId[0] = e.key;
      this.assignment();
    },
    async openModel(type,row){
      // console.log(type)
      this.selectId[0] = type;
      await this.childenOpen(row);
      await this.assignment();
    },
    //赋值
    assignment(){
      let row = this.selectRow;
      // console.log('row',JSON.stringify(row))
      if(this.selectId[0] === 'qc') { //去程
        // console.log('departrueIsBooking',row.departrueIsBooking)
        this.qcForm = {
          "departrueIsBooking": row.departrueIsBooking? 1:0, //去程是否订票0否1是
          "departureDate": row.departureDate, //出发日期
          "departureStarTime": row.departureStarTime ? row.departureStarTime.slice(11):null, //出发时间段开始时间
          "departureEndTime": row.departureEndTime ? row.departureEndTime.slice(11):null, //出发时间段结束时间
          "departureVehicle": row.departureVehicle ? row.departureVehicle :undefined, //交通工具
          "departureStation": row.departureStation, //始发站
          "destination": row.destination, //到达站
          "flightTrainNumber": row.flightTrainNumber, //航班、车次
          "departureStatus": row.departureStatus, //去程信息确认状态 0待确认 1待出票 2已出票
          "departureRemark": row.departureRemark, //去程备注
        }
        this.startTime = row.departureStarTime ? moment(row.departureStarTime.slice(11), 'HH:mm'):null;
        this.endTime = row.departureEndTime ? moment(row.departureEndTime.slice(11), 'HH:mm'):null;
        // console.log('赋值去程',this.qcForm)
      }
      else if(this.selectId[0] === 'fc'){ //返程

        this.qcForm = {
          "departrueIsBooking": row.fcDepartrueIsBooking? 1:0, //去程是否订票0否1是
          "departureDate": row.fcDepartureDate, //出发日期
          "departureStarTime": row.fcDepartureStarTime ? row.fcDepartureStarTime.slice(11):null, //出发时间段开始时间
          "departureEndTime": row.fcDepartureEndTime ? row.fcDepartureEndTime.slice(11):null, //出发时间段结束时间
          "departureVehicle": row.fcDepartureVehicle ? row.fcDepartureVehicle :undefined, //交通工具
          "departureStation": row.fcDepartureStation, //始发站
          "destination": row.fcDestination, //到达站
          "flightTrainNumber": row.fcFlightTrainNumber, //航班、车次
          "departureStatus": row.fcDepartureStatus, //去程信息确认状态 0待确认 1待出票 2已出票
          "departureRemark": row.fcDepartureRemark, //去程备注
        }
        // console.log('赋值返程',this.qcForm)
        this.startTime = row.fcDepartureStarTime ? moment(row.fcDepartureStarTime.slice(11), 'HH:mm'):null;
        this.endTime = row.fcDepartureEndTime ? moment(row.fcDepartureEndTime.slice(11), 'HH:mm'):null;

      }
      else if(this.selectId[0] === 'jc'){ //接乘
        this.jcForm = {
          "isJie": row.isJie? 1:0, //是否接 1是0否
          "jieTmId": row.jieTmId ? row.jieTmId : undefined, //接内部人id
          "jieStatus": row.jieStatus, //接状态0待安排1已安排
        }
        if(row.jieTmId){
          for (let i=0;i<this.linkmanList.length;i++){
            if(this.linkmanList[i].id === row.jieTmId){
              this.selectLinkmanList = this.linkmanList[i];
            }
          }
        }
        if(row.departureStarTime && row.departureEndTime){
          this.selectLinkmanList.time = row.departureStarTime + '-' +row.departureEndTime.slice(11)
        }
        this.selectLinkmanList.jieTmPhone = row.jieTmPhone;
        this.selectLinkmanList.jieTmCarNumber = row.jieTmCarNumber;
        this.selectLinkmanList.jieTmCarInfo = row.jieTmCarInfo;
      }
      else if(this.selectId[0] === 'sc'){ //送乘
        this.jcForm = {
          "isJie": row.isSong ? 1:0, //是否接 1是0否
          "jieTmId": row.songTmId ? row.songTmId :undefined, //接内部人id
          "jieStatus": row.songStatus, //接状态0待安排1已安排
        }
        if(row.jieTmId){
          for (let i=0;i<this.linkmanList.length;i++){
            if(this.linkmanList[i].jieTmId === row.jieTmId){
              this.selectLinkmanList = {
                // this.linkmanList[i]
                  "jieTmId": this.linkmanList[i].id, //接内部人id
                  "jieTmName": this.linkmanList[i].name, //姓名
                  "jieTmPhone": this.linkmanList[i].phone, //手机号
                  "jieTmCarInfo": this.linkmanList[i].carInfo, //车辆信息
                  "jieTmCarNumber": this.linkmanList[i].carNumber, //车牌号
              };
            }
          }
        }
        if(row.fcDepartureStarTime && row.fcDepartureEndTime){
          this.selectLinkmanList.time = row.fcDepartureStarTime + '-' +row.fcDepartureEndTime.slice(11)
        }
        this.selectLinkmanList.jieTmPhone = row.songTmPhone;
        this.selectLinkmanList.jieTmCarNumber = row.songTmCarInfo;
        this.selectLinkmanList.jieTmCarInfo = row.songTmCarNumber;
      }
      else if(this.selectId[0] === 'zs'){ //住宿信息
        this.zsForm = {
          "isHotel": row.isHotel ? 1:0, //是否住宿1是0否
          "hotelCheckInDate": row.hotelCheckInDate, //入住日期
          "hotelDepartureDate": row.hotelDepartureDate, //离店日期
          "hotelId": row.hotelId ? row.hotelId : undefined, //住宿酒店id
          "roomType": row.roomType, //房型要求
          "hotelCohabitant": row.hotelCohabitant, //同住人
          "hotelRoomNumber": row.hotelRoomNumber, //房间号
          "hotelStatus": row.hotelStatus ? row.hotelStatus : 0, //住宿信息确认状态 0待确认 1待安排 2已安排
        }
        // console.log('zsForm',this.zsForm)
      }
    },
    openVideoUrl(url){
      window.open(url)
    },
    async content_surface_plot_original_video(e) {
      this.disabledVideo = true;
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息

          // await this.Crew_getSignature();
          // await this.uploadVideoFile_toyun();
          const res = await update_Video(this.vFile)
          if(res.code === 200){
            this.CrewForm.fileId = res.data.fileId;
            this.CrewForm.video = res.data.mediaUrl;
          }
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
      document.getElementById('up_video_surface_plot').value = null;
      this.disabledVideo = false;
    },
    async content_pdf(name) {
      this.$set(this.CrewForm,'progress',10)
      let inputDOM = this.$refs.pdfRef.files[0];
      const fileVal = await update_File(inputDOM)
      if (fileVal.code === 0) {
        this.$set(this.CrewForm,'name',inputDOM.name)
        this.$set(this.CrewForm,'courseware',fileVal.data.url)
        this.$set(this.CrewForm,'progress',100)
        document.getElementById(name).value = null;
      }
    },
    delectPdfList(){
      this.CrewForm.courseware = '';
      this.CrewForm.name = '';
      this.CrewForm.progress = '';
    },
    addCrewlectureCancel (row) {
      this.CrewForm = {
        modality:undefined,
        video:'',
      };
      this.disabledVideo = false;
      this.visiblelectureCrew = false;
      this.$refs.LectureCrewRef.resetFields();
    },
    addCrewSubmitLecture(){
      this.$refs.LectureCrewRef.validate(valid => {
        if (valid) {
          if(this.titleCrew === '新建'){
            this.CrewForm.conventionMemberId = this.selectRow.id;
            this.CrewForm.conventionCode = this.conventionCode;
            this.postConfereeTeachAddInfo(this.CrewForm);
          }else if(this.titleCrew === '修改'){
            this.putConfereeTeachAddInfo(this.CrewForm);
          }
        } else {
          return false;
        }
      });
    },
    //新增参会人员课件
    async postConfereeTeachAddInfo(data) {
      const response = await confereeTeachAddInfo(data)
      if(response.code === 200){
        this.$message.success("新增成功！");
        await this.getConfereeTeachSelectAll(this.selectRow.id); //刷新页面
        this.addCrewlectureCancel();
      }
    },

    //修改参会人员课件
    async putConfereeTeachAddInfo(data) {
      const response = await confereeTeachUpInfo(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.getConfereeTeachSelectAll(this.selectRow.id); //刷新页面
        this.addCrewlectureCancel();
      }
    },
    jkOpen (row) {
      this.CrewForm = {
        "id": row.id,
        "subject": row.subject,
        "modality": row.modality,
        "courseware": row.courseware,
        "progress":row.courseware ? 100:0,
        "video": row.video,
      }
      this.titleCrew = '修改';
      this.visiblelectureCrew = true;
    },
    jkOpenAdd(){
      this.titleCrew = '新建';
      this.visiblelectureCrew = true;
    },
    //获取参会人员课件
    async getConfereeTeachSelectAll(conventionMemberId) {
      let data = {
        page:this.currentLecture,
        pageSize:this.pageSizeLecture
      }
      const response = await confereeTeachSelectAll(conventionMemberId,data)
      if(response.code === 200){
        this.LectureData = response.data;
        this.Lecturetotal = response.count;
      }
    },
    //删除参会人员课件
    async confirmLecture(row) {
      let data = {
        id:row.id,
      }
      const response = await confereeTeachDelInfo(data)
      if(response.code === 200){
        this.$message.success("删除成功！");
        await this.getConfereeTeachSelectAll(this.selectRow.id); //刷新页面
      }
    },
    PageNOchangeLecture(pageNo, pageSize) {
      this.currentLecture = pageNo;
      this.pageSizeLecture = pageSize;
      this.getConfereeTeachSelectAll(this.selectRow.id); //刷新页面
    },
    //获取单条数据信息
    async getOneInfo(id) {
      let data = {
        id:id
      }
      const response = await confereeSelectAllNewOne(this.conventionCode,data)
      if(response.code === 200){
        this.selectRow = response.data[0];
      }else {
        this.$message.warning(response.message)
      }
    },
    async childenOpen(row) {
      // this.selectRow = {...row}
      await this.getOneInfo(row.id)
      await this.getConfereeTeachSelectAll(row.id)
      this.lectureStatus = String(row.lectureStatus);
      this.childenVisible = true
    },
    childenCancel () {
      this.childenVisible = false;
      this.LectureData = [];
      this.selectRow = {};
      this.selectId[0] = 'jk'
      this.getConfereeSelect();
      this.getSelectConventionMemberStatusNumber();
    },
    childenOk () {
      this.childenVisible = false
    },
    topChange (value, type) {
      // console.log(1)
      // this.changeData[type] = value.target.value;
    },
    selectBtn(value,type){
      if(this.changeData[type] === value){
        this.changeData[type] = null;
      }else {
        this.changeData[type] = value;
      }
      this.getConfereeSelect();
    },
    onSearch () {
      this.getConfereeSelect(); //刷新页面
    },
    // handleChangestatus (type) {
    //   this.status = type;
    //   this.getConfereeSelect(); //刷新页面
    // },
    handleChangeTmId () {
      this.getConfereeSelect(); //刷新页面
    },
    confirmCancel(row){
      let data = {
        "id":row.id,//参会人员id
      }
      this.delConfereeDelInfo(data);
    },
    //批量删除
    async deleteBatches() {
      let data = {
        "idList": this.selectedRowKeys
      }
      const response = await confereeDelInfoBatches(data)
      if (response.code === 200) {
        this.$message.success("删除成功！");
        this.selectedRowKeys = [];
        await this.getConfereeSelect(); //刷新页面
      }
    },
    //删除参会人员信息
    async delConfereeDelInfo(data) {
      const response = await confereeDelInfo(data)
      if(response.code === 200){
        this.$message.success("删除成功！");
        await this.getConfereeSelect(); //刷新页面
      }
    },
    //获取不可选择的小时
    disabledStartHours(){
      let endTime = moment(this.endTime).format('HH:mm')
      if(endTime){
        let hours = [];
        let hour = Number.parseInt(endTime.substring(0, 2));
        for (let i = hour+1; i < 24; i++) {
          hours.push(i);
        }
        return hours
      }
    },
    //获取不可选择的分钟
    disabledStartMinutes(selectedHour){
      let endTime = moment(this.endTime).format('HH:mm')
      if(endTime){
        let minutes = [];
        let hour = Number.parseInt(endTime.substring(0, 2));
        let minute = Number.parseInt(endTime.substring(3, 5));
        if(selectedHour>=hour){
          for (let i = minute+1; i < 60; i++) {
            minutes.push(i);
          }
        }
        return minutes
      }
    },
    selectStartTime(time) {
      this.qcForm.departureStarTime = moment(time).format('HH:mm');
    },
    selectEndTime(time) {
      this.qcForm.departureEndTime = moment(time).format('HH:mm');
    },
    // 关闭的回调
    blurStartTime(open){
      // 关闭且未选择时间
      if(!open && this.qcForm.departureStarTime.includes(':')){
        this.endTime = moment(this.qcForm.departureStarTime, 'HH:mm');
      }
    },
    //获取不可选择的小时
    disabledEndHours(){
      let startTime = moment(this.startTime).format('HH:mm')
      if(startTime){
        let hours = [];
        let hour = Number.parseInt(startTime.substring(0, 2));
        for (let i = 0; i < hour; i++) {
          hours.push(i);
        }
        return hours
      }
    },
    //获取不可选择的分钟
    disabledEndMinutes(selectedHour){
      let startTime = moment(this.startTime).format('HH:mm')
      if(startTime){
        let minutes = [];
        let hour = Number.parseInt(startTime.substring(0, 2));
        let minute = Number.parseInt(startTime.substring(3,5));
        if(selectedHour<=hour){
          for (let i = 0; i < minute; i++) {
            minutes.push(i);
          }
        }
        return minutes
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.ant-radio-button-wrapper{
  text-align: center;
  padding: 3px 6px;
  line-height: 20px;
  height: 50px;
}
.ParticipantsNew {
  background-color: white;
  //padding: 10px;

  .header {
    padding: 10px 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
  }
}
.twoButton {
  width: 150px;text-align: center;
}
.oneButton {
  width: 100px;text-align: center
}
.uploadpdf {
  width: 350px;
  height: 150px;
  background-image: url("../../assets/img_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}

.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}

.modalWindow{
  ::v-deep .ant-modal{
    top:0
  }
}

</style>
