import { get, put, post, del } from "./base";

export function getConvention(conventionId){
    return get("/conferenceAffairs/task/convention",{conventionId})
}
// 获取分类
export function getCategory(taskTemplateCode,parentId){
	return get(`/meet/task/category?taskTemplateCode=${taskTemplateCode}&parentId=${parentId}`) 
}
// 添加任务  
export function postConvention(data){
    return post("/conferenceAffairs/task/convention",data)
}
// 删除任务
export function delConvention(id) {
  return del(`/conferenceAffairs/task/convention/${id}`);
}
// 根据模板添加任务
export function postTaskTemplate(taskTemplateCode,conventionId){
  return post(`/conferenceAffairs/task/convention/taskTemplate?taskTemplateCode=${taskTemplateCode}&conventionId=${conventionId}`) 
}
// 修改任务 
export function putConvention(data){
  return put("/conferenceAffairs/task/convention",data)
}
// 获取执行人  
export function getMember(data){
  return get("/conferenceAffairs/task/convention/member",data)
}
// 获取二级任务   
export function getConventionSecond(data){
  return get("/conferenceAffairs/task/convention/second",data)
}
// 获取会议列表  
export function getConventionList(data){
  return get("/conferenceAffairs/task/convention/list",data)
}
// 复制其他会议任务  
export function postCopyConvention(targetConventionId,conventionId){
  return post(`/conferenceAffairs/task/convention/copyConvention?targetConventionId=${targetConventionId}&conventionId=${conventionId}`) 
}
// 获取会议总体统计信息  
export function getTopDetail(conventionId){
  return get(`/conferenceAffairs/task/convention/top/detail?&conventionId=${conventionId}`) 
}
// 获取任务模板  
export function getTemplateListOpen(pageNo,pageSize){
  return get(`/meet/task/threeTask?pageNo=${pageNo}&pageSize=${pageSize}`)
}