<template>
  <a-spin :spinning="spinning">
  <div class="indexBox">
    <div v-if="dataState === 0" style="background-color: #fff;padding-bottom: 50px;">
      <div class="taskAllocation">
        <h3>任务分配</h3>
      </div>
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;padding-top:50px">
        <div style="display: flex;">
          <!-- 复制其他会议任务 -->
           <div class="themeBox" style="margin-right:200px">
              <div class="imageBox" @click="openMeetingModal">
                <div>
                  <div style="display: flex;justify-content: center;">
                    <img src="../../assets/复制其他会议任务.png" alt="">
                  </div>
                  <div style="display: flex;justify-content: center;text-align:center;margin-top:10px">
                    <p>适用于会议执行人员和会议任务相差不大的情况</p>
                  </div>
                </div>
             </div>
             <h2>复制其他会议任务</h2>
           </div>
           <div class="themeBox">
             <div class="imageBox"  @click="openMeeting2Modal">
              <div>
                <div style="display: flex;justify-content: center;text-align:center;margin-top:10px">
                  <img src="../../assets/选择模板.png" alt="">
                </div>
                <p>适用于首次使用和全新的会议</p>
              </div>

             </div>
             <h2>选择模板</h2>
           </div>
        </div>
      </div>
    </div>

    <div v-if="dataState === 1" >
      <div class="TaskDetails">
        <a-alert style="height: 40px;"  type="info" show-icon size="small">
          <span slot="message" style="font-weight: normal">任务总数：<span style="font-weight:bold">{{ TotalNumberOfTasks.allDetailTaskCount }} 项</span>&nbsp;&nbsp;&nbsp;&nbsp;未开始：{{ TotalNumberOfTasks.unAcceptCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;进行中：{{ TotalNumberOfTasks.haveAcceptCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已完成：{{ TotalNumberOfTasks.haveFinishedCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已逾期：<span style="color:red">{{ TotalNumberOfTasks.haveOverdueCount }}</span>&nbsp;项</span>
        </a-alert>
      </div>
      <div v-for="(items,index) in meetingTableData" :key="index">
        <a-menu mode="inline" :open-keys="openKeys" style="width: 100%" @openChange="onOpenChange">
          <a-sub-menu :key="index">
            <span slot="title"><span>{{ items.fistTaskName }}</span></span>
            <a-table :columns="BeforeTheMeetingColumns" :data-source="items.meetTaskConventions" :pagination="false"
              :row-key="(record) => record.id"
              style="margin-bottom:40px;margin-top:10px;">
              <span slot="taskStatus" slot-scope="text,record,index">
                <a-tag v-if="text === 'un_start'" color="Grey">未开始</a-tag>
                <a-tag v-if="text === 'in_progress'" color="orange">进行中</a-tag>
                <a-tag v-if="text === 'have_finished'" color="green">已完成</a-tag>
                <span v-if="text === ''">-</span>
              </span>
             <span slot="detailTaskCount" slot-scope="text,record,index">
<!--               <a @click="openModel(record.taskTypeCode)" v-if="record.taskTypeCode === 'hyjiudian' || record.taskTypeCode === 'jdzhusu' || record.taskTypeCode === 'jiesongz'">查看</a>-->
<!--               <span v-else>-->
                 <a v-if="record.detailTaskCount === 0" style="color: rgba(0, 0, 0, 0.65);">0</a>
               <template v-else>
                 <div style="width: 100%" @click="openModal(record.id)">
                   <a-progress :percent="record.finishedDetailTaskCount/record.detailTaskCount*100" size="small"  :format="percent =>  record.finishedDetailTaskCount  + '/'+ record.detailTaskCount"/>
                 </div>
               </template>
<!--               </span>-->
             </span>
             <span slot="planFinishDate" slot-scope="text,record,index">
               <!-- <template>
                 <div style="width: 170px">
                   <a-progress :percent="record.finishedDetailTaskCount/record.detailTaskCount" size="small" status="active" :format="percent => record.detailTaskCount  + '/'+ record.finishedDetailTaskCount"/>
                 </div>
               </template> -->
               <span v-if="record.planFinishDate">
                 {{ record.planFinishDate }}
               </span>
               <a v-else @click="setDate(record.id)">
                未设置
               </a>
             </span>
             <span slot="actualFinishDate" slot-scope="text,record,index">
               <span v-if="record.actualFinishDate">
                 {{record.actualFinishDate}}
               </span>
               <span v-else>
                 -
               </span>
             </span>
              <span slot="operation" slot-scope="text, record">
                <a @click="editForm(items,record)">编辑</a>&ensp;
                <a-popconfirm
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delItem(record.id)"
                 ><a href="#">删除</a>
                 <!-- @confirm="del(record.code)" -->
                 </a-popconfirm>
              </span>
              <span slot="members" slot-scope="text, record">

              </span>
              <span slot="taskName" slot-scope="text,record,index">
                <span style="color:red" v-if="record.haveOverdue == 1">
                    [已逾期]
                </span>
                <span>
                  {{ text }}
                </span>
              </span>
            </a-table>
            <a-button @click="addTask(items)" style="width:100%;">+ &nbsp; 添加任务</a-button>
          </a-sub-menu>
        </a-menu>
        <div style="width:100%;height: 15px;background-color: #edeff2;">
        </div>
        <!-- <a-menu mode="inline" :open-keys="open2Keys" style="width: 100%" @openChange="onOpen2Change">
          <a-sub-menu key="sub2">
            <span slot="title"><span>会中事务管理</span></span>
            <a-table :columns="BeforeTheMeetingColumns" :data-source="afterTheMeetingData" :pagination="false"
              :row-key="(record) => record.id"
              style="margin-bottom:40px;margin-top:10px;">
            </a-table>
          </a-sub-menu>
        </a-menu> -->
      </div>
    </div>

    <!-- 选择会议 -->
    <a-modal
      width="700px"
      @cancel="handleCancel"
      :footer="null"
      title="选择会议"
      :visible="MeetingShow">
      会议名称：
      <a-input style="width: 200px;" placeholder="请输入" v-model="searchName" :allowClear="thistrue" @change="search">
      </a-input>
      <a-button @click="search" style="margin-left: 15px" type="primary">查询</a-button>
      <a-table :columns="tableColumns" :data-source="tableData" :pagination="false"
        :row-key="(record) => record.id"
        style="margin-bottom:20px;margin-top:10px;"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio'  }">
        <span slot="date" slot-scope="text,record,index">
          {{record.beginDate}} 至 {{ record.endDate.substring(5,record.endDate.length ) }}
       </span>
      </a-table>
      <div style="height: 90px;">
        <a-pagination
         size="small"
         show-quick-jumper
         show-size-changer
         :page-size.sync="pagination.pageSize"
         :total="pagination.total"
         v-model="pagination.current"
         @change="pageChange"
         @showSizeChange="pageChange"
         :page-size-options="pageSizeOptions"
         style="float:right" />
      </div>
    <div
        :style="{
            position: 'absolute',
            textAlign:'right',
            bottom: '0',
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <a-button @click="handleCancel"> 取消 </a-button> &ensp;
      <a-button type="primary" @click="handleOk" :disabled="disable"> 确定 </a-button>
    </div>
    </a-modal>

    <!-- 选择模板 -->
    <!-- <a-spin :spinning="spinning"> -->
    <a-modal
      width="700px"
      @cancel="handle2Cancel"
      :footer="null"
      title="选择模板"
      :visible="TemplateShow">
      <a-table :columns="table2Columns" :data-source="table2Data" :pagination="false"
        :row-key="(record) => record.id"
        style="margin-bottom:40px;margin-top:10px;"
        :row-selection="{ selectedRowKeys: selected2RowKeys, onChange: onSelect2Change, type: 'radio'  }">
        <span slot="detail" slot-scope="text,record,index">
          <span v-if="record.detail">
            {{record.detail}}
          </span>
          <span v-else>
            -
          </span>
       </span>
      </a-table>
    <div
        :style="{
            position: 'absolute',
            textAlign:'right',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <a-button @click="handle2Cancel"> 取消 </a-button> &ensp;
      <a-button type="primary" @click="handle2Ok" :disabled="disable"> 确定 </a-button>
    </div>
    </a-modal>
  <!-- </a-spin> -->

    <!-- 添加/修改任务 -->
    <a-modal
      title="添加/修改任务"
      :visible="addShow"
      :footer="null"
      @cancel="handle3Cancel"
       width="600px"
    >
      <a-form-model
        :model="addform"
        :rules="rules"
        ref="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
        style="margin-bottom:50px"
      >
        <a-form-model-item label="一级任务" prop="name">
          {{ this.fistTaskName }}
        </a-form-model-item>
        <a-form-model-item
          label="分类"
          prop="taskType"
        >
          <a-select
            v-model="addform.taskType"
            placeholder="请选择"
          >
            <a-select-option v-for="item in typeList" :key="item.id" :value="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="任务名称" prop="taskName">
          <a-input v-model="addform.taskName" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="任务执行人" prop="taskExecuteMember">
            <a-select
                v-model="addform.taskExecuteMember"
                option-filter-prop="children"
                placeholder="请选择"
                show-search
            >
              <a-select-option
                v-for="item in taskExecuteMemberList"
                :value="item.id"
                :key="item.id"
                >
                {{item.name}} - {{ item.groupName }} - {{ item.memberTypeName }}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item  label="计划完成日期">
          <a-date-picker
            v-model="addform.planFinishDate"
            style="width: 100%"
            :disabledDate="disabledDate"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="任务状态" v-if="addOrEdit === 'edit'">
          <a-select v-model="addform.taskStatus" placeholder="请选择">
            <a-select-option value="un_arrange">
              待下发
            </a-select-option>
            <a-select-option value="un_accept">
              待接受
            </a-select-option>
            <a-select-option value="have_accept">
              已接受
            </a-select-option>
            <a-select-option value="in_progress">
              进行中
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
      </a-form-model>

      <div
        :style="{
            position: 'absolute',
            textAlign:'right',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <div v-if="addOrEdit === 'add'">
        <a-button @click="handle3Cancel"> 取消 </a-button> &ensp;
        <a-button type="primary" @click="handle3Ok('continue')"> 提交并继续 </a-button> &ensp;
        <a-button @click="handle3Ok('')"> 提交 </a-button>
      </div>

     <div v-if="addOrEdit === 'edit'">
       <a-button @click="handle3Cancel"> 取消 </a-button> &ensp;
       <a-button type="primary" @click="handle3Ok()"> 提交 </a-button>
     </div>

    </div>
    </a-modal>

    <!-- 设置日期 -->
    <a-modal
      title="设置计划完成日期"
      :visible="dateShow"
      :footer="null"
      @cancel="handle4Cancel"
       width="600px"
    >
      <a-form-model
        :model="dateform"
        :rules="daterules"
        ref="dateform"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item  label="计划完成日期" prop="planFinishDate">
          <a-date-picker
            v-model="dateform.planFinishDate"
            style="width: 100%"
            :disabledDate="disabledDate"
          />
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
            position: 'absolute',
            textAlign:'right',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <a-button @click="handle4Cancel"> 取消 </a-button> &ensp;
      <a-button type="primary" @click="handle4Ok()"> 确定 </a-button> &ensp;
    </div>
    </a-modal>

    <!-- 抽屉 -->
    <a-drawer
      title="查看详细任务"
      placement="right"
      @close="drawerClose"
      :visible="DetailVisible"
      :get-container="false"
      width="1000"
    >
      <a-alert style="height: 40px;"  type="info" show-icon size="small">
        <span slot="message" style="font-weight: normal">任务总数：<span style="font-weight:bold">{{ TotalDeatailNumber.allDetailTaskCount }} 项</span>&nbsp;&nbsp;&nbsp;&nbsp;待接受：{{ TotalDeatailNumber.unAcceptCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已接受：{{ TotalDeatailNumber.haveAcceptCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已完成：{{ TotalDeatailNumber.haveFinishedCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已逾期：<span style="color:red">{{ TotalDeatailNumber.haveOverdueCount }}</span>&nbsp;项</span>
      </a-alert>
      <!-- <a-alert style="height: 40px;margin-top: 5px"  type="info" show-icon size="small">
        <span slot="message" style="font-weight: normal">任务总数：<span style="font-weight:bold">{{ TotalDeatailNumber.allDetailTaskCount }} 项</span>&nbsp;&nbsp;&nbsp;&nbsp;未开始：{{ TotalDeatailNumber.allStartDetailTaskCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;进行中：{{ TotalDeatailNumber.allProgressDetailTaskCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已完成：{{ TotalDeatailNumber.allFinishedDetailTaskCount }} 项&nbsp;&nbsp;&nbsp;&nbsp;已逾期：<span style="color:red">{{ TotalNumberOfTasks.deadLineTaskCount }}</span>项</span>
      </a-alert> -->
      <a-table :columns="detailMeetingColumns" :data-source="detailTask" :pagination="false"
        :row-key="(record) => record.id"
        style="margin-bottom:40px;margin-top:10px;">
        <span slot="serialNumber" slot-scope="text, record,index">
          <span>{{index+1}}</span>
        </span>
        <span slot="taskStatus" slot-scope="text,record,index">
          <a-tag v-if="text === 'un_accepted'" color="grey">待接受</a-tag>
          <a-tag v-if="text === 'have_accepted'" color="orange">已接受</a-tag>
          <a-tag v-if="text === 'have_finished'" color="green">已完成</a-tag>
        </span>
        <span slot="actualFinishDate" slot-scope="text,record,index">
          <span v-if="record.actualFinishDate">
            {{record.actualFinishDate}}
          </span>
          <span v-else>
            -
          </span>
        </span>
        <span slot="filesss" slot-scope="text,record,index">
            <span v-if="!record.file || record.file.length ==0">
              -
            </span>
            <span v-else>
              <!-- {{record.file}} -->
              <a @click="windowOpen(text,'附件')">查看</a>
            </span>
          </span>
          <span slot="summary" slot-scope="text,record,index">
            <span v-if="record.summary">
              <!-- {{record.summary}}
                -->
                <a @click="windowOpen(text,'总结')">查看</a>
            </span>
            <span v-else>
              -
            </span>
          </span>
        <span slot="taskName" slot-scope="text,record,index">
          <span style="color:red"  v-if="record.haveOverdue == 1">
              [已逾期]
          </span>
          <span>
            {{ text }}
          </span>
        </span>
      </a-table>
    </a-drawer>

    <!-- 查看附件/总结 -->
    <a-modal
      :title="AttachmentsOrSummary"
      :visible="seeShow"
      :footer="null"
      @cancel="()=>{this.seeShow = false;}"
       width="600px"
    >
      <div v-if="AttachmentsOrSummary == '附件'" style="width:80%;margin:auto">
        <div v-for="(item,index) in summarydata" :key="index">
          <img :src="item" style="width:100%;height: auto;margin-bottom:20px">
        </div>
      </div>

      <div v-if="AttachmentsOrSummary == '总结'">
        {{summarydata}}
      </div>

    </a-modal>

<!--    会议酒店管理-->
    <HotelManagement
        v-if="HotelManagementVisible"
        title="会议酒店管理"
        :visible="HotelManagementVisible"
        v-on:closeMain="HotelManagementCloseMian"
    />
<!--    住宿酒店管理-->
    <AccommodationManagement
        v-if="AccommodationManagementVisible"
        title="住宿酒店管理"
        :visible="AccommodationManagementVisible"
        v-on:closeMain="AccommodationManagementCloseMian"
    />
<!--    车辆管理-->
    <VehicleManagement
        v-if="VehicleManagementVisible"
        title="车辆管理"
        :visible="VehicleManagementVisible"
        v-on:closeMain="VehicleManagementCloseMian"
    />
  </div>
</a-spin>
</template>

<script>
import {getTemplateListOpen,getConvention,getCategory,postConvention,delConvention,postTaskTemplate,putConvention,getConventionSecond,getMember,getConventionList,postCopyConvention} from "@/service/managementOfMeetings";
// import {getTemplateListOpen} from '@/service/meetingTemplat_api.js'
import moment from "moment";
import HotelManagement from "@/components/meet/HotelManagement";
import AccommodationManagement from "@/components/meet/AccommodationManagement";
import VehicleManagement from "@/components/meet/VehicleManagement";

export default {
  components: {HotelManagement,VehicleManagement,AccommodationManagement},
  props:{
    code:{
      type:String,
    },
    conventionId:{
      type:String,
    },
  },
  data(){
    return{
      HotelManagementVisible:false,
      AccommodationManagementVisible:false,
      VehicleManagementVisible:false,
      thistrue:true,
      AttachmentsOrSummary:'',
      disable:false,
      seeShow:false,
      summarydata:null,
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 5,
        pageNum: 1,
      },
      pageSizeOptions: ['5', '10', '15', '20', '30'],
      spinning:false,
      TotalNumberOfTasks:{},
      TotalDeatailNumber:{},
      dateform:{},
      dateShow:false,
      detailTask:[],
      addOrEdit:'',
      DetailVisible:false,
      typeList:[],
      dataForm:[],
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      addform:{},
      openKeys: [],
      open2Keys: ['sub2'],
      rules: {
        taskName: [{ required: true, message: '请输入', trigger: 'blur' }],
        taskType: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      daterules: {
        planFinishDate: [{ required: true, message: '请选择', trigger: 'change' }],
        // taskType: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      addShow:false,
      BeforeTheMeetingData:[],
      dataState:1,
      afterTheMeetingData:[],
      MeetingShow:false,
      TemplateShow:false,
      tableData:[],
      table2Data:[{
        name:111,
        date:2020
      }],
      selectedRowKeys: [],
      selected2RowKeys: [],
      searchName:'',
      tableColumns: [
        {
          title: '会议名称',
          dataIndex: 'title',
          width: '60%',
        //   scopedSlots: { customRender: 'requestUri' }
        },
        {
          title: '会议日期',
          // dataIndex: 'beginDate',
        //   width: '16%',
          scopedSlots: { customRender: 'date' }
        },
      ],
      table2Columns: [
        {
          title: '模版名称',
          dataIndex: 'name',
        //   width: '16%',
        //   scopedSlots: { customRender: 'requestUri' }
        },
        {
          title: '模版描述',
          dataIndex: 'detail',
        //   width: '16%',
          scopedSlots: { customRender: 'detail' }
        },
      ],
      BeforeTheMeetingColumns:[
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          width:100,
          scopedSlots: { customRender: 'taskStatus' },
          // fixed:'left',
        },
        {
          title: '分类',
          dataIndex: 'taskType',
          // fixed:'left',
          align: 'center',
        },
        {
          title: '任务名称',
          dataIndex: 'taskName',
          // fixed:'left',
          scopedSlots: { customRender: 'taskName' }
        },
        {
          title: '详细任务数量',
          // dataIndex: 'e',
          align: 'center',
          scopedSlots: { customRender: 'detailTaskCount' }
        },
        {
          title: '计划完成日期',
          dataIndex: 'planFinishDate',
          align: 'center',
          scopedSlots: { customRender: 'planFinishDate' }
        },
        {
          title: '任务负责人',
          // dataIndex: 'c',
          // scopedSlots: { customRender: 'members' }
          dataIndex: 'members',
          key: 'members',
          customRender(arr){
            if(arr){
              return arr.map(item=>{
                   return item.name;   //使其返回数组中的一项
               }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
             }
            }

        },
        {
          title: '实际完成日期',
          dataIndex: 'actualFinishDate',
          align: 'center',
          scopedSlots: { customRender: 'actualFinishDate' }
        },
        {
          title: '操作',
        //   dataIndex: 'as',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      detailMeetingColumns:[
        {
          title: '序号',
          // dataIndex: 'taskName',
          scopedSlots: { customRender: 'serialNumber' },
        },
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          scopedSlots: { customRender: 'taskStatus' },
          // fixed:'left',
        },
        {
          title: '任务名称',
          dataIndex: 'taskName',
          // fixed:'left',
          scopedSlots: { customRender: 'taskName' },
        },
        {
          title: '执行人',
          dataIndex: 'members',
          key: 'members',
          customRender(arr){
            if(arr){
              return arr.map(item=>{
                   return item.name;   //使其返回数组中的一项
               }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
             }
            }

        },
        {
          title: '计划完成日期',
          align: 'center',
          dataIndex: 'planFinishDate',
        },
        {
          title: '实际完成日期',
          dataIndex: 'actualFinishDate',
          align: 'center',
          scopedSlots: { customRender: 'actualFinishDate' }
        },
        {
          title: '附件',
          dataIndex: 'file',
          align: 'center',
          scopedSlots: { customRender: 'filesss' }
        },
        {
          title: '总结',
          dataIndex: 'summary',
          align: 'center',
          scopedSlots: { customRender: 'summary' }
        },
      ],
      meetingTableData:[],
      fistTaskName:'',
      selectCode:'',
      setDateId:'',
      taskExecuteMemberList:[],
      selectCopyCode:'',
      userCopy_id:'',
    }
  },
  created() {
    // this.code = this.$route.query.code;
    this.getTable()
    this.gettaskExecuteMemberList()
  },
  methods:{
    openModel(type){
    //  v-if="record.taskTypeCode === 'hyjiudian'
      //  || record.taskTypeCode === 'jdzhusu'
      //  || record.taskTypeCode === 'jiesongz'"

      if(type === 'hyjiudian'){ //会议酒店
        this.HotelManagementVisible = true;
      }else if(type === 'jdzhusu'){ //住宿酒店
        this.AccommodationManagementVisible = true;
      }else if(type === 'jiesongz'){ //车辆管理
        this.VehicleManagementVisible = true;
      }

    },
    HotelManagementCloseMian(val){
      this.HotelManagementVisible = val;
    },
    AccommodationManagementCloseMian(val){
      this.AccommodationManagementVisible = val;
    },
    VehicleManagementCloseMian(val){
      this.VehicleManagementVisible = val;
    },
    // 获取列表   getConvention
    async getTable(){
      this.spinning = true
      // this.dataState = 1
      const response = await getConvention(this.conventionId)
      if(response.code === 0){
        if(response.data.data.length !== 0 ){
          this.dataState = 1
          this.meetingTableData = response.data.data
          // 赋值菜单key
          this.openKeys = []
          this.meetingTableData.forEach((item,index) =>{
            this.openKeys.push(index)
          })
          this.TotalNumberOfTasks = response.data.taskStatus
        }else{
          this.dataState = 0
        }
        // this.dataState = 0
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    // 分页查询
    pageChange(page_no, page_size) {
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      // console.log("??",this.pagination)
      this.getMeetings()
    },
    // 搜索框清空  inputCancel
    inputCancel(){
      this.searchName = ''
    },
    search(){
      // searchName
      this.pagination.current = 1
      this.pagination.pageSize = 5
      this.getMeetings()
    },
    async openMeetingModal(){
      this.MeetingShow = true
      this.getMeetings()
    },
    async getMeetings(){
      const data = {
        title:this.searchName,
        pageNo:this.pagination.current,
        pageSize:this.pagination.pageSize
      }
      // 获取模板
      const response = await getConventionList(data)
      if(response.code === 0){
        this.tableData = response.data
        this.pagination.total =  response.count
      }else {
        this.$message.warning(response.message)
      }
    },
    // 开链接
    windowOpen(text,summary){
      // window.open(text)
      this.seeShow = true
      this.AttachmentsOrSummary = summary
      this.summarydata = text
    },
    // 获取任务执行人
    async gettaskExecuteMemberList(){
      const data = {
        // "id":this.setDateId,
        "pageSize":999,
        "pageNo": 1
      }
      const response = await getMember(data)
      if(response.code === 0){
        this.taskExecuteMemberList = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    setDate(id){
      this.addOrEdit = 'setDate'
      this.dateShow =  true
      this.setDateId = id
    },
    handle4Ok(){
      this.$refs.dateform.validate(async (valid) => {
        if (valid) {
          this.puteditData()
        }
       }
      )
    },
    // 修改任务接口
    async puteditData(){
      if(this.addOrEdit === 'setDate'){
        const data = {
          "id":this.setDateId,
          "planFinishDate":this.dateform.planFinishDate.format('YYYY-MM-DD'),
          "parentId": 0
        }
        const response = await putConvention(data)
        if(response.code === 0){
          this.$message.success("操作成功");
          this.getTable()
          this.dateShow = false
          this.dateform = {}
          this.$refs.dateform.resetFields()
        }else {
          this.$message.warning(response.message)
        }
      }else if(this.addOrEdit === 'edit'){
        const data = {
          "id":this.setDateId,
          "taskType":  this.addform.taskType,//任务类型
          "taskName": this.addform.taskName,//任务名称
          "planFinishDate": this.addform.planFinishDate? typeof this.addform.planFinishDate === "object" ? this.addform.planFinishDate.format('YYYY-MM-DD'):this.addform.planFinishDate:undefined,
          "taskExecuteMember":this.addform.taskExecuteMember? this.addform.taskExecuteMember instanceof Array? this.addform.taskExecuteMember:new Array(this.addform.taskExecuteMember):undefined,//任务执行人
          // "taskExecuteMember":this.addform.taskExecuteMember,//任务执行人
          // "parentId": 0,
          // "conventionId": this.dataForm.conventionId, //会议id
          // "templateTaskCode": this.dataForm.templateTaskCode, //任务模板code
          // "fistTaskName":   this.fistTaskName, //一级任务名
          // "fistTaskId": this.dataForm.fistTaskId, //一级任务id
          // "taskType": this.addform.taskType,//任务类型
          // "taskName": this.addform.taskName,//任务名称
          // "planFinishDate": this.addform.planFinishDate.format('YYYY-MM-DD'),
          // "taskExecuteMember": ["ddddd","ddddd"],//任务执行人
          // "parentId": 0
        }
        const response = await putConvention(data)
        if(response.code === 0){
          this.$message.success("修改成功");
          this.addShow = false
          this.addform = {}
          this.$refs.form.resetFields()
          this.getTable()
        }else {
          this.$message.warning(response.message)
        }
      }
    },

    drawerClose(){
      this.DetailVisible = false
    },
    async openModal(id){
      this.DetailVisible = true
      const data = {
        parentId:id,
        conventionId:this.conventionId
      }
      const response = await getConventionSecond(data)
      if(response.code === 0){
        this.detailTask = response.data.data
        this.TotalDeatailNumber = response.data.taskStatus
      }else {
        this.$message.warning(response.message)
      }
    },
    disabledDate (current) {
      return current && current < moment().subtract(1, 'days').endOf('day')
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onOpen2Change(openKeys) {
      const latestOpenKey = openKeys.find(key => this.open2Keys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.open2Keys = openKeys;
      } else {
        this.open2Keys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 删除任务
    async delItem(id){
        const response = await delConvention(id)
          if(response.code === 0){
            this.$message.success("删除成功");
            this.getTable()
          }else {
            this.$message.warning(response.message)
          }
    },
    handleCancel(){
      this.MeetingShow = false
      this.pagination.pageSize = 5
      this.pagination.current = 1
      this.searchName = ''
      this.selectedRowKeys = []
    },
    async handleOk(){
      this.disable = true
      this.test()
    },
    async test(){
      this.spinning = true
      this.MeetingShow = false
       const response = await postCopyConvention(this.userCopy_id,this.conventionId)
       if(response.code === 0){
         this.selectedRowKeys = []
         // 需要改变状态 === 1 展示任务
         this.dataState = 1
         this.getselectTable()
       }else {
         this.$message.warning(response.message)
       }
       this.disable = false
       this.spinning = false
     },
     async getselectTable(){
      this.spinning = true
      const response = await getConvention(this.conventionId)
      if(response.code === 0){
        if(response.data.data.length !== 0 ){
          this.dataState = 1
          this.meetingTableData = response.data.data
          // 赋值菜单key
          this.openKeys = []
          this.meetingTableData.forEach((item,index) =>{
            this.openKeys.push(index)
          })
          this.TotalNumberOfTasks = response.data.taskStatus
          this.$message.success("选择成功");
          this.$emit('getTop'); //子组件向父组件传值（开关）
          // 父组件刷新
        }else{
          this.dataState = 0
          this.$message.warning("该模板为空请重新选择");
        }
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //获取id
    onSelectChange(selectedRowKeys) {
      this.userCopy_id = ''
      //选择的列表的id
      this.userCopy_id = selectedRowKeys  //目标会议id
      this.selectedRowKeys = selectedRowKeys;
      // this.tableData.forEach(item => {
      //   if(this.userCopy_id[0] === item.id){
      //      this.selectCopyCode = item.code
      //   }
      // })
    },
    handle2Cancel(){
      this.TemplateShow = false
      this.selected2RowKeys = []
      this.user_id = []
    },
    async handle2Ok(){
      this.spinning = true
      this.disable = true
      this.TemplateShow = false
      const response = await postTaskTemplate(this.selectCode,this.conventionId)
      if(response.code === 0){
        this.selected2RowKeys = []
        // 需要改变状态 === 1 展示任务
        this.dataState = 1
        this.getselectTable()
        // this.TemplateShow = false
      }else {
        this.$message.warning(response.message)
      }
      this.disable = false
      this.spinning = false
    },
    async openMeeting2Modal(){
      this.TemplateShow = true
      // 获取模板
      const response = await getTemplateListOpen(1,999)
      if(response.code === 0){
        this.table2Data = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    handle3Cancel(){
      this.addShow = false
      this.addform = {}
      this.$refs.form.resetFields()
    },
    handle4Cancel(){
      this.dateShow = false
      this.dateform = {}
      this.$refs.dateform.resetFields()
    },
    handle3Ok(hadleType){
      this.$refs.form.validate(async (valid) => {
        if (valid) { // 新增
          if(this.addOrEdit == 'add'){
            const data = {
            "conventionId": this.$route.query.id, //会议id
            "templateTaskCode": this.dataForm.templateTaskCode, //任务模板code
            "fistTaskName":   this.fistTaskName, //一级任务名
            "fistTaskId": this.dataForm.fistTaskId, //一级任务id
            "taskType": this.addform.taskType,//任务类型
            "taskName": this.addform.taskName,//任务名称
            "taskStatus": "un_start",//任务状态
            "planFinishDate": this.addform.planFinishDate?this.addform.planFinishDate.format('YYYY-MM-DD') :'',
            "taskExecuteMember": new Array(this.addform.taskExecuteMember),//任务执行人
            "parentId": 0
          }
          const response = await postConvention(data)
          if(response.code === 0){
            this.$message.success("提交成功");
            // 提交并关闭
            if(hadleType !== 'continue'){
              this.addShow = false
            }
            this.addform = {}
            this.getTable()
            this.$refs.form.resetFields()
          }else {
            this.$message.warning(response.message)
          }
          }else if(this.addOrEdit == 'edit'){
            this.puteditData()
          }
        }
       }
      )
    },
    editForm(items,record){
      this.addShow = true
      this.fistTaskName = items.fistTaskName
      this.dataForm = items
      // 获取分类
      this.getType()
      this.addOrEdit = 'edit'
      this.addform = JSON.parse(JSON.stringify(record))
      console.log(111,this.addform )
      this.setDateId = record.id
    },
    onSelect2Change(selectedRowKeys) {
      // this.user_id = ''
      //选择的列表的id
      this.user_id = selectedRowKeys
      this.table2Data.forEach(item => {
        if(this.user_id[0] === item.id){
           this.selectCode = item.code
        }
      })
      //显示是否被选中
      this.selected2RowKeys = selectedRowKeys;
    },
    addTask(items){
      console.log('items',items)
      this.addShow = true
      this.fistTaskName = items.fistTaskName

      this.dataForm = items
      // 获取分类
      this.getType(items.templateTaskCode,items.fistTaskId)
      this.addOrEdit = 'add'
    },
    async getType(templateTaskCode,fistTaskId){
      const response = await getCategory(templateTaskCode,fistTaskId)
      if(response.code === 0){
        // getCategory(taskTemplateCode,parentId){
        this.typeList = response.data
      }else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.indexBox{
    background-color: #edeff2;
    height: 100vh;
    // margin-bottom:100px;
    .TaskDetails{
        background-color: #edeff2;
        border-bottom:10px solid #edeff2;
    }
    ::v-deep .ant-menu-submenu-title{
        font-weight:bold;
        color:black;
        font-size:15px;
        border-bottom: 1px solid #e9e9e9
    }
    ::v-deep .ant-menu-submenu .ant-menu-sub{
        padding:0 20px 15px 20px;
    }
    ::v-deep .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
        color:#ccc;
        transform: scale(3);
    }
    ::v-deep  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
        color:#ccc;
        transform: scale(3);
    }
    ::v-deep .ant-btn {
        border : 1px dashed  #d9d9d9;
    }
    ::v-deep .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
      top: 34%;
    }
    ::v-deep .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
}
    .taskAllocation{
      height: 50px;
      padding:0 20px;
    //   padding-top:100px;
      border-bottom:1px solid #edeff2;
      display: flex;
      align-items: center;
      h3{
        font-weight:bold;
        margin-bottom:0
      }
    }
    .themeBox{
        // display: flex;
        justify-content: center;
        align-items: center;
        // background-color: pink;
        .itemBox{
            // display: flex;
            // justify-content: center;
        }
      .imageBox{
        width:220px;
        height: 300px;
        border: 1px solid #dbdbdb;
        border-radius:10px;
        margin-bottom:20px;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0 12px;
        img{
          width:128px;
          height: 128px;
        }
      }
      h2{
        // margin-top:20px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
    }

}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
::v-deep .ant-modal-body{
  min-height: 200px;
}
</style>
